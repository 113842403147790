import { Col, DatePicker, Form, Input, InputNumber, message, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  PaymentDocumentAttachedTransactions,
  PaymentDocumentSwitchControl,
} from '@entities';
import FilterAndAddContacts from '@entities/filters/FilterAndAddContacts.tsx';
import { Select } from '@components';
import { useAccount } from '@hooks';
import { GTMEventName, sendGTMEvent } from '@utils';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { currencyCodes, PaymentDocumentUnit } from '@constants';

import { getTranslatesByType } from './helpers';
import * as S from './styled';

type Document = Schemas.Document;

type PaymentDocumentDetailsFormProps = {
  type: PaymentDocumentUnit;
  details: Document;
  onUpdate: (document: Document | null) => void;
  onAttachTransaction: (document: Document | null) => void;
  onRefreshFile: () => void;
  hasSwitches?: boolean;
};

const PaymentDocumentDetailsForm: FC<PaymentDocumentDetailsFormProps> = ({
  type,
  details,
  onUpdate,
  onAttachTransaction,
  onRefreshFile,
  hasSwitches = true,
}) => {
  const { companyId, userAccess } = useAccount();

  const translates = getTranslatesByType(type);

  const [selectedStatus, setSelectedStatus] = useState<string>('');
  const [selectedContact, setSelectedContact] =
    useState<Schemas.Contact | null>(null);

  const [form] = Form.useForm();

  const { mutate: updateFile } = useDocumentController_updateOneById();

  const isReviewed = details.documentMetadata.isReviewed;
  const isCreatedByUser = !!details.documentMetadata?.isCreatedByUser;

  useEffect(() => {
    if (details) {
      const { contact, documentMetadata: data } = details;

      form.setFieldsValue({
        number: data.number,
        contact: contact?.id,
        description: data.description,
        issueDate: data.issueDate ? dayjs(data.issueDate) : null,
        dueDate: data.dueDate ? dayjs(data.dueDate) : null,
        amount: data.amount,
        currency: data.currency,
      });

      setSelectedContact(contact);
      setSelectedStatus(details?.documentMetadata.status || '');
    }
  }, [details, form]);

  const handleUpdateDetails = useDebouncedCallback(() => {
    if (details.documentMetadata?.isReviewed) {
      form.submit();
    }
  }, 1000);

  const handleSubmit = (
    values: Document['documentMetadata'] & { contact: string },
  ) => {
    const requestParameter = {
      companyId: companyId!,
      id: details.id!,
    };
    const formData = new FormData();

    if (values.contact) {
      formData.append('contactId', values.contact!);
    }

    updateFile(
      {
        parameter: requestParameter,
        requestBody: formData as Schemas.UpdateDocumentDto,
      },
      {
        onSuccess: () => {
          updateFile(
            {
              parameter: requestParameter,
              requestBody: {
                documentMetadata: {
                  ...details.documentMetadata,
                  ...values,
                  status: selectedStatus,
                  ignoreDuplicate: true,
                  isReviewed: true,
                },
              } as Schemas.UpdateDocumentDto,
            },
            {
              onSuccess: (response) => {
                if (type === 'expenses') {
                  sendGTMEvent(GTMEventName.ApprovedExpenceData);
                }

                onUpdate(response);
                message.success(translates.successfullyUpdated);
              },
            },
          );
        },
      },
    );
  };

  const handleSelectStatus = (status: string) => {
    setSelectedStatus(status);

    if (details.documentMetadata?.isReviewed) {
      handleUpdateDetails();
    }
  };

  return (
    <S.Container>
      {hasSwitches && (
        <PaymentDocumentSwitchControl
          type={type}
          status={selectedStatus}
          onChange={handleSelectStatus}
        />
      )}

      <Form
        form={form}
        onFinish={handleSubmit}
        requiredMark={false}
        colon={false}
        layout="vertical"
      >
        <Form.Item label={translates.numberLabel} name="number">
          <Input
            placeholder={translates.numberPlaceholder}
            size="large"
            onChange={handleUpdateDetails}
            disabled={isCreatedByUser}
          />
        </Form.Item>

        <Form.Item label={translates.contactLabel} name="contact">
          <FilterAndAddContacts
            selectedContact={selectedContact}
            placeholder={translates.contactPlaceholder}
            onChange={handleUpdateDetails}
            isDisabled={isCreatedByUser}
          />
        </Form.Item>

        <Form.Item label={translates.descriptionLabel} name="description">
          <Input.TextArea
            placeholder={translates.descriptionPlaceholder}
            size="large"
            onChange={handleUpdateDetails}
          />
        </Form.Item>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label={translates.issueDateLabel} name="issueDate">
              <DatePicker
                placeholder={translates.issueDatePlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
                disabled={isCreatedByUser}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={translates.dueDateLabel} name="dueDate">
              <DatePicker
                placeholder={translates.dueDatePlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                format="DD.MM.YYYY"
                disabled={isCreatedByUser}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 0]}>
          <Col span={12}>
            <Form.Item label={translates.amountLabel} name="amount">
              <InputNumber
                placeholder={translates.amountPlaceholder}
                size="large"
                onChange={handleUpdateDetails}
                decimalSeparator=","
                disabled={isCreatedByUser}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item label={translates.currencyLabel} name="currency">
              <Select
                options={currencyCodes.map((code) => ({
                  label: code,
                  value: code,
                }))}
                onChange={handleUpdateDetails}
                disabled={isCreatedByUser}
                size="large"
                showSearch
              />
            </Form.Item>
          </Col>
        </Row>

        {!isReviewed && (
          <Form.Item noStyle>
            <S.ButtonSubmit type="primary" htmlType="submit" block>
              {translates.buttonSubmit}
            </S.ButtonSubmit>
          </Form.Item>
        )}

        {userAccess?.transactions && (
          <PaymentDocumentAttachedTransactions
            type={type}
            id={details.id}
            transactions={details?.transactions}
            potentialTransactions={details?.potentialTransactions}
            onAttachTransaction={onAttachTransaction}
            onRefreshFile={onRefreshFile}
          />
        )}
      </Form>
    </S.Container>
  );
};

export default PaymentDocumentDetailsForm;
