import { Modal as BaseModal } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const modalStyles = css`
  .ui-modal-footer.ui-modal-footer {
    margin-top: 32px;
  }
`;

export const Modal = styled(BaseModal)<{ hasHeader?: boolean }>`
  && .ui-modal-content {
    border-radius: 20px;
    padding: ${(p) => (p.hasHeader ? '24px 32px 32px' : '32px')};
    box-shadow: 0 4px 21px 0 #00000024;
  }
`;

export const Header = styled.header`
  font-size: 24px;
  font-weight: 600;
`;

export const Description = styled.div`
  font-size: 14px;
`;
