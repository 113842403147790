import type { DefaultError, QueryKey } from '@tanstack/query-core';
import {
  RefetchOptions,
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';

type PaginatedData<T> = {
  pages: Record<number, T>;
  refetch: Record<
    number,
    (options?: RefetchOptions) => Promise<UseQueryResult<T>>
  >;
};

type CustomInfiniteQueryOptions<TData = unknown, TQueryFnData = TData> = {
  page: number;
  visiblePages?: number[];
  queryFn: (params: { page: number }) => Promise<TQueryFnData>;
  getHasNextPage?: (lastPage: TQueryFnData) => boolean;
  getHasPrevPage?: (lastPage: TQueryFnData) => boolean;
  getTotalItems?: (lastPage: TQueryFnData) => number;
  getCurrentPage: (lastPage: TQueryFnData) => number;
};

export const useCustomInfiniteQuery = <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryFn'
  > &
    CustomInfiniteQueryOptions<TData, TQueryFnData>,
) => {
  const [data, setData] = useState<PaginatedData<TQueryFnData>>();
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const loadingPages = useMemo(
    () =>
      options.visiblePages?.length
        ? options.visiblePages.filter(
            (visiblePage) => !data?.pages[visiblePage],
          )
        : [1],
    [options.visiblePages, data],
  );

  const { data: rawData, isFetching } = useQueries({
    queries: loadingPages.map((page) => ({
      queryKey: [...options.queryKey, page],
      queryFn: () => options.queryFn({ page }),
      refetchOnMount: false,
      staleTime: 0,
    })),
    combine: (result) => ({
      data: result.reduce(
        (acc, query) => {
          if (!query.data) return acc;

          const page = options.getCurrentPage(query.data);
          acc.pages[page] = query.data;
          acc.refetch[page] = query.refetch;

          return acc;
        },
        { pages: {}, refetch: {} } as PaginatedData<TQueryFnData>,
      ),
      isFetching: result.some((query) => query.isFetching),
    }),
  });

  const updateData = (
    prevData: PaginatedData<TQueryFnData> | undefined,
    newData: PaginatedData<TQueryFnData>,
  ): PaginatedData<TQueryFnData> => {
    const result: PaginatedData<TQueryFnData> = {
      pages: {},
      refetch: {},
    };

    let totalItems: number | undefined;

    const allPages = Object.keys(newData.pages).map((page) =>
      parseInt(page, 10),
    );
    const minPage = Math.min(...allPages);
    const maxPage = Math.max(...allPages);

    Object.entries(newData.pages).forEach(([page, data]) => {
      if (!data) return;

      const pageNumber = parseInt(page, 10);

      result.pages[pageNumber] = data;
      result.refetch[pageNumber] = newData.refetch[pageNumber];
      totalItems = options.getTotalItems?.(data) ?? totalItems;
    });

    const hasNextPage =
      newData.pages[maxPage] &&
      (options.getHasNextPage?.(newData.pages[maxPage]) ?? false);
    const hasPrevPage =
      newData.pages[minPage] &&
      (options.getHasPrevPage?.(newData.pages[minPage]) ?? false);

    setHasPrevPage(hasPrevPage);
    setHasNextPage(hasNextPage);
    if (totalItems !== undefined) {
      setTotalItems(totalItems);
    }
    setIsLoading(false);

    return {
      pages: {
        ...prevData?.pages,
        ...result.pages,
      },
      refetch: {
        ...prevData?.refetch,
        ...result.refetch,
      },
    };
  };

  useEffect(() => {
    setData((prevData) => updateData(prevData, rawData));
    // eslint-disable-next-line
  }, [rawData]);

  const reset = useCallback(() => {
    setData(undefined);
    setHasNextPage(false);
    setHasPrevPage(false);
    setTotalItems(0);
  }, []);

  const refetchPages = useCallback(
    async (visiblePages?: number[]) => {
      const result = await Promise.all<UseQueryResult<TQueryFnData>>(
        (visiblePages || [])
          .reduce(
            (acc, page) => {
              const refetch = data?.refetch[page];

              if (!refetch) return acc;

              return [...acc, refetch];
            },
            [] as (() => Promise<UseQueryResult<TQueryFnData>>)[],
          )
          .map((refetch) => refetch()),
      );

      setData(() =>
        updateData(
          undefined,
          result.reduce(
            (acc, queryResult) => {
              if (!queryResult?.data) return acc;

              const page = options.getCurrentPage(queryResult.data);
              acc.pages[page] = queryResult.data;
              acc.refetch[page] = queryResult.refetch;

              return acc;
            },
            { pages: {}, refetch: {} } as PaginatedData<TQueryFnData>,
          ),
        ),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  return {
    loadingPages,
    isLoading,
    isFetching,
    reset,
    refetchPages,
    data,
    totalItems,
    hasNextPage,
    hasPrevPage,
  };
};
