import { Button } from 'antd';
import { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import { useAccount, useOnboarding } from '@hooks';
import {
  useClaimSuccessfulPaymentIntent,
  useUpdateIncorporation,
} from '@hooks-api';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { GTMEventName, sendGTMEvent } from '@utils';

import * as S from './styled';

const OnboardingCheckoutForm: FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { companyId } = useAccount();

  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const { updateScreen, updateIncorporation, paymentIntentSecret } =
    useOnboarding();
  const { mutate: update } = useUpdateIncorporation();

  const { mutate: claimSuccessfulPayment } = useClaimSuccessfulPaymentIntent();

  const updateIsPaying = useCallback(() => {
    update({
      parameter: {
        companyId: companyId!,
      },
      requestBody: {
        isPaying: true,
        isSubmittedToKyc: true,
      },
    });
  }, [companyId, update]);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!paymentIntentSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(paymentIntentSecret)
      .then(({ paymentIntent }) => {
        const paymentIntentStatus = paymentIntent!.status;
        if (paymentIntentStatus === 'succeeded' || paymentIntentStatus === 'requires_capture') {
          claimSuccessfulPayment(
            {
              parameter: {
                companyId: companyId!,
              },
            },
            {
              onSuccess: () => {
                sendGTMEvent(GTMEventName.PaidIncorporation);

                updateIncorporation({ isPaid: true, isSubmittedToKyc: true });
                updateScreen('MESSAGE_PROCESS_PAYMENT_SENT', {
                  active: true,
                });
              },
              onError: () => {
                setMessage(t('onboarding.payment.errors.not_paid')());
              },
            }
          );
        } else if (paymentIntentStatus === 'processing') {
          updateIsPaying();
          updateIncorporation({ isPaying: true, isSubmittedToKyc: true });
          updateScreen('MESSAGE_PROCESS_PAYMENT_SENT', { active: true });
        } else if (paymentIntentStatus === 'requires_payment_method') {
          setMessage(t('onboarding.payment.errors.not_paid'));
        } else {
          setMessage(t('onboarding.payment.errors.unexpected')());
        }
      });
  }, [
    claimSuccessfulPayment,
    companyId,
    paymentIntentSecret,
    stripe,
    updateIncorporation,
    updateIsPaying,
    updateScreen,
  ]);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}${window.location.pathname}`,
      },
    });

    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage(t('onboarding.payment.errors.unexpected')());
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
  } as StripePaymentElementOptions;

  return (
    <div>
      {message && <S.ErrorMessage message={message} type="error" />}
      <form onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />

        <S.Pay justify="flex-end">
          <Button
            type="primary"
            htmlType="submit"
            disabled={isLoading || !stripe || !elements}
          >
            {t('onboarding.buttonPay')()}
          </Button>
        </S.Pay>
      </form>
    </div>
  );
};

export default OnboardingCheckoutForm;
