import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  HttpStatusCode,
  type InternalAxiosRequestConfig,
} from 'axios';
import queryString from 'query-string';

import {
  type HttpMethod,
  type ObjectLike,
  type QueryParameters,
  createClient,
} from '@api-client/generated/client';
import {
  ROUTES_ADDITIONAL_PUBLIC,
  STORAGE_COMPANY_KEY,
  STORAGE_TOKEN_KEY,
} from '@constants';

type AxiosConfig = {
  requestBody?: ObjectLike;
  httpMethod?: HttpMethod;
  queryParameters?: QueryParameters;
} & InternalAxiosRequestConfig;

const createUrlWithQueryParameters = (
  url: string = '',
  parameters: QueryParameters
) => {
  const transformedParams: Record<string, unknown | undefined> = {};

  Object.keys(parameters).map((key) => {
    transformedParams[key] = parameters[key].value;
  });

  return `${url}?${queryString.stringify(transformedParams, {
    arrayFormat: 'comma',
  })}`;
};

axios.interceptors.request.use(
  async (config: AxiosConfig) => {
    const { headers, url, httpMethod, requestBody, queryParameters } = config;

    const tokenJWT = localStorage.getItem(STORAGE_TOKEN_KEY);

    if (tokenJWT) {
      headers.Authorization = `Bearer ${tokenJWT}`;
    }

    return {
      ...config,
      url: queryParameters
        ? createUrlWithQueryParameters(url, queryParameters)
        : url,
      method: httpMethod,
      data: requestBody,
    };
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (
      error.response?.status === HttpStatusCode.Unauthorized &&
      !ROUTES_ADDITIONAL_PUBLIC.includes(location.pathname)
    ) {
      localStorage.removeItem(STORAGE_TOKEN_KEY);
      localStorage.removeItem(STORAGE_COMPANY_KEY);

      location.replace('/login');
    }

    return error;
  }
);

/**
 * @description Using the client API without a hook
 *
 * @example
 *
 * apiClient.AccountsController_getAccounts({
 *   parameter: {
 *    companyId: 'c046d1b3-0c52-4b01-af9e-b2320d0b34b2',
 *   },
 * })
 * .then((response) => {
 *   // Response handler
 * })
 * .catch((error) => {
 *   // Error handler
 * });
 *
 */

class AxiosProxy {
  async request<T = unknown, D = unknown>(
    config: AxiosRequestConfig<D>
  ): Promise<T> {
    return axios.request<T, AxiosResponse<T>, D>(config).then((res) => {
      if (axios.isAxiosError(res)) {
        const axiosError: AxiosError = res;
        throw axiosError;
      } else {
        return res.data;
      }
    });
  }
}

const apiClient = createClient(
  new AxiosProxy(),
  import.meta.env.VITE_ROUTE_API_BASE_URL || ''
);

export default apiClient;
