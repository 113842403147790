import { Avatar as BaseAvatar, Flex } from 'antd';

import styled from '@emotion/styled';

export const Group = styled(Flex)`
  padding: 0 30px;
  gap: 8px;
  width: 100%;
  margin-top: 24px;
`;

export const MessageWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'files',
})<{
  files: boolean;
}>`
  min-width: 160px;
  max-width: ${({ files }) => (files ? '360px' : '75%')};
  position: relative;
`;

export const Header = styled('h5', {
  shouldForwardProp: (prop) => prop !== 'files' && prop !== 'position',
})<{
  position: 'left' | 'right';
}>`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 12px;
  padding: 0 16px;
  margin-bottom: 5px;
  font-weight: 600;
  line-height: 20px;
  margin-left: ${({ position }) => (position === 'left' ? '48px' : 0)};
  text-align: ${({ position }) => position === 'right' && 'right'};
`;

export const Avatar = styled(BaseAvatar)`
  min-width: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
`;
