import styled from '@emotion/styled';

export const ListItem = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  position: relative;
  border-width: 2px;
  border-color: ${({ theme, isActive: isActive }) =>
    isActive ? theme.colors.primary : theme.colors.strokeGrey};
  border-style: solid;
  border-radius: 12px;
  background: ${({ isActive, theme }) =>
    isActive ? theme.colors.darkgrey : 'transparent'};

  &:hover {
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

export const ListItemHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListItemName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Count = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text400};
`;

export const Remove = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
`;
