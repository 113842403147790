import { ButtonProps } from 'antd';
import { createContext } from 'react';

export type OpenConfirmationConfig = {
  title: string;
  description?: string;
  confirmParams?: { text: string; } & ButtonProps;
};

type ConfirmationContext = {
  open: (config: OpenConfirmationConfig) => Promise<boolean>;
  close: () => void;
};

export const ConfirmationContext = createContext({} as ConfirmationContext);
