import { Flex, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Button } from '@ui-kit/Button/Button';
import { IconPlus } from '@assets';
import {
  PageMeta,
  ProjectCard,
  ProjectsList,
  ProjectsListEmpty,
  SearchControl,
} from '@entities';
import {
  ProjectListTabs,
  toBackendProjectStatus,
  toFrontendProjectStatus,
} from '@entities/projects';
import { Schema, schema, useProjectEdit, useProjectList } from '@entities/projects/hooks';
import ProjectForm from '@entities/projects/ProjectForm/ProjectForm';
import { Loader } from '@components';
import { useQueryParams } from '@hooks/useQueryParams';
import { useStorage } from '@hooks/useStorage';
import { getDefaultValues } from '@utils/zod';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { ConfirmationContext } from '@context';
import { ApiTypes } from '@api';

import ProjectsEmpty from './ProjectsEmpty';
import * as S from './styled';

type Project = ApiTypes.ProjectResponseDto;

function Projects() {
  const confirmation = useContext(ConfirmationContext);

  const [wasEmptyStateShown, setWasEmptyStateShown] = useStorage(
    'project-welcome-screen',
    false,
  );

  const { queryParams, setQueryParams, setQueryParam } = useQueryParams({
    defaultValues: getDefaultValues(schema),
    schema,
  });

  const projectList = useProjectList(queryParams);
  const {mutateAsync: editProject} = useProjectEdit();

  const [selectedProject, setSelectedProject] = useState<null | Project>(null);
  const [isProjectFormModalOpened, setIsProjectFormModalOpened] =
    useState(false);
  const [projectFormAction, setProjectFormAction] = useState<'edit' | 'create'>(
    'create',
  );

  useEffect(() => {
    setSelectedProject(projectList.data?.records[0] || null);
  }, [projectList.data]);

  useEffect(() => {
    if (projectList.data?.records.length) {
      setWasEmptyStateShown(true);
    }
  }, [projectList.isPending]); // eslint-disable-line

  const handleFilterBySearch = useDebouncedCallback(
    (term: string) => setQueryParam('term', term),
    DEFAULT_TIMEOUT_FOR_SEARCH,
  );

  function handleTabClick(key: ProjectListTabs) {
    if (key === 'all') {
      setQueryParam('status', undefined);
    } else {
      setQueryParam('status', toBackendProjectStatus[key] as Schema['status']);
    }
  }

  async function handleArchiveProject() {
    if (!selectedProject) return;

    const answer = await confirmation.open({
      title: t('projects.popupArchive.title')(),
      description: t('projects.popupArchive.description')(),
      confirmParams: {
        text: t('projects.popupArchive.buttonConfirm')(),
      },
    });

    if (!answer) return;

    try {
      // @ts-expect-error we need to fix project entity on backend
      await editProject({
        ...selectedProject,
        status: 'inactive',
      });
      message.success(t('projects.success.update')());
    } catch (err) {
      console.error(err);
      message.error(t('projects.error.update')());
    }
  }

  const handleOnAfterModalSubmit = () => {
    projectList.refetch();
    setIsProjectFormModalOpened(false);
  };

  const openModalWithActionType = (actionType: typeof projectFormAction) => {
    setProjectFormAction(actionType);
    setIsProjectFormModalOpened(true);
  };

  return (
    <Flex gap={32} flex="1 0 800px" vertical>
      <PageMeta title={t('projects.title')()} />

      {!wasEmptyStateShown ? (
        <ProjectsEmpty onAddProject={() => setWasEmptyStateShown(true)} />
      ) : (
        <>
          <S.Title>{t('projects.title')()}</S.Title>

          <Flex align="center" justify="space-between">
            <SearchControl
              value={queryParams.term}
              onChange={(e) => {
                handleFilterBySearch(e.target.value);
              }}
              onClear={() => setQueryParams({ term: '' })}
            />

            <Button
              size="small"
              icon={<IconPlus />}
              onClick={() => openModalWithActionType('create')}
            >
              {t('projects.buttonAdd')()}
            </Button>
          </Flex>

          <S.TwoColumns>
            <ProjectsList
              loading={projectList.isPending && !projectList.isRefetching}
              hasNextPage={
                (queryParams.page || 1) < (projectList.data?.metadata?.totalPages || 1)
              }
              activeKey={
                queryParams.status
                  ? toFrontendProjectStatus[queryParams.status]
                  : 'all'
              }
              selectedProjectId={selectedProject?.id || null}
              projects={projectList.data?.records || []}
              onTabClick={handleTabClick}
              onProjectClick={(project) => setSelectedProject(project)}
              onLoadMore={() => setQueryParam('page', (queryParams.page || 1) + 1)}
            />

            {selectedProject === null ? (
              <Flex justify="center">
                <ProjectsListEmpty />
              </Flex>
            ) : (
              <>
                {projectList.isPending && <Loader />}

                <ProjectCard
                  project={selectedProject}
                  onProjectArchive={() => handleArchiveProject()}
                  onProjectEdit={() => openModalWithActionType('edit')}
                />
              </>
            )}
          </S.TwoColumns>

          <ProjectForm
            actionType={projectFormAction}
            project={
              projectFormAction === 'edit' ? selectedProject : undefined
            }
            isOpened={isProjectFormModalOpened}
            onAfterSubmit={handleOnAfterModalSubmit}
            onCancel={() => setIsProjectFormModalOpened(false)}
          />
        </>
      )}
    </Flex>
  );
}

export default Projects;
