import { Button } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PageMeta,
  TransactionIngestAlertUploaded,
  TransactionsTable,
  TransactionsTableFilterForm,
} from '@entities';
import TransactionsInfiniteTable from '@entities/transactions/TransactionsInfiniteTable';
import * as transactionsTableSchema from '@entities/transactions/TransactionsTable/schema';
import { ScrollRestoration } from '@components/InfiniteTable/ScrollRestoration';
import { useAccount, useAppState } from '@hooks';
import { useQueryParams } from '@hooks/useQueryParams';
import { getDefaultValues } from '@utils/zod';

import * as S from './styled';

const Transactions = () => {
  const navigate = useNavigate();
  const { userAccess, featuresStatus } = useAccount();

  const { queryParams, setQueryParams } = useQueryParams({
    schema: transactionsTableSchema.queryParamsSchema,
    defaultValues: getDefaultValues(transactionsTableSchema.queryParamsSchema),
  });

  const { isVisibleContentAfterImport, updateVisibilityContentAfterImport } =
    useAppState();

  const handleShowAllImports = () => {
    updateVisibilityContentAfterImport(false);
    navigate('/transactions');
  };

  useEffect(
    () => () => {
      updateVisibilityContentAfterImport(false);
    },
    [updateVisibilityContentAfterImport],
  );

  useEffect(() => {
    if (userAccess && !userAccess?.transactions) {
      navigate('/documents');
    }
  }, [navigate, userAccess]);

  return (
    <S.Root gap={32} vertical>
      <PageMeta title={t('transactionsPage.title')()} />

      {isVisibleContentAfterImport && (
        <TransactionIngestAlertUploaded
          onManageImports={() => navigate(`/csv-imports`)}
          onShowAll={handleShowAllImports}
        />
      )}

      <S.Title>{t('transactionsPage.title')()}</S.Title>
      {featuresStatus?.infiniteTransactions === true ? (
        <ScrollRestoration name="transactions">
          <TransactionsInfiniteTable
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            filterForm={({ onFormUpdate, total }) => (
              <TransactionsTableFilterForm
                queryParams={queryParams}
                onFormUpdate={onFormUpdate}
                total={total}
                right={
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate('/accounts')}
                  >
                    {t('transactionsPage.button.importCsv')()}
                  </Button>
                }
              />
            )}
          />
        </ScrollRestoration>
      ) : (
        <TransactionsTable
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          filterForm={({ onFormUpdate, total }) => (
            <TransactionsTableFilterForm
              queryParams={queryParams}
              onFormUpdate={onFormUpdate}
              total={total}
              right={
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={() => navigate('/accounts')}
                >
                  {t('transactionsPage.button.importCsv')()}
                </Button>
              }
            />
          )}
        />
      )}
    </S.Root>
  );
};

export default Transactions;
