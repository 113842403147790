import { Button, Flex, Form, type ModalProps, Row, Switch } from 'antd';
import { FC, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { IconTrash } from '@assets';
import { Amount } from '@entities';
import { Schemas } from '@api-client/generated/types';
import {
  DEFAULT_CURRENCY_CODE,
  InvoiceItemUnit,
  InvoiceZeroVATReason,
  VATPercents,
} from '@constants';

import InvoiceFormCard from '../../form/InvoiceFormCard';
import InvoiceFormControl from '../../form/InvoiceFormControl';
import InvoiceModalWrapper from '../InvoiceModalWrapper';
import * as helpers from './helpers';
import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type InvoiceModalItemActionProps = {
  type: 'add' | 'edit';
  item: InvoiceItem | null;
  currency: Schemas.DocumentMetadata['currency'];
  onSubmit: (values: InvoiceItem) => void;
  onCancel: VoidFunction;
  onRemove: (id: string) => void;
} & ModalProps;

const InvoiceModalItemAction: FC<InvoiceModalItemActionProps> = ({
  item,
  type,
  currency,
  open,
  onSubmit,
  onCancel,
  onRemove,
}) => {
  const [form] = Form.useForm();

  const [values, setValues] = useState<InvoiceItem | null>(null);

  useEffect(() => {
    if (item) {
      const { unitPrice, taxRate, settings } = item;

      const params = {
        ...item,
        taxRate: taxRate || 0,
        unitPrice: settings.isUnitPriceWithTaxInluded
          ? helpers.getUnitPriceWithTaxRate(unitPrice, taxRate)
          : unitPrice,
      };

      form.setFieldsValue(params);
      setValues(params);
    }
  }, [form, item]);

  const handleCancel = () => {
    setValues(null);
    onCancel();
    form.resetFields();
  };

  const handleRemoveItem = (id: string) => {
    onRemove(id);
    handleCancel();
  };

  const handleSubmit = (values: InvoiceItem) => {
    const { unitPrice, quantity, taxRate, settings } = values;

    onSubmit({
      ...values,
      id: uuidv4(),
      unitPrice: settings.isUnitPriceWithTaxInluded
        ? helpers.getUnitPriceWithoutTaxRate(unitPrice, taxRate)
        : helpers.getPriceWithRounding(unitPrice),
      totalPrice: settings.isUnitPriceWithTaxInluded
        ? helpers.getTotalWithTaxInluded(unitPrice, quantity)
        : helpers.getTotalWithoutTaxInluded(unitPrice, quantity, taxRate),
    });

    handleCancel();
  };

  return (
    <InvoiceModalWrapper
      open={open}
      title={
        type === 'add'
          ? t('invoiceGenerator.addItem.title')()
          : t('invoiceGenerator.addItem.titleEdit')()
      }
      onCancel={handleCancel}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={(_, values) => setValues(values)}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          quantity: 1,
          unit: 'item',
          taxRate: helpers.defaultPercent,
          settings: {
            isUnitPriceWithTaxInluded: false,
          },
        }}
      >
        <InvoiceFormCard>
          <InvoiceFormControl
            type="input"
            form={{
              label: t('invoiceGenerator.addItem.fieldName.label')(),
              name: 'name',
              rules: [{ required: true }],
            }}
          />

          <InvoiceFormControl
            type="textarea"
            form={{
              label: t('invoiceGenerator.addItem.fieldDescription.label')(),
              name: 'description',
            }}
            control={{
              rows: 3,
            }}
          />
        </InvoiceFormCard>

        <InvoiceFormCard>
          <Row gutter={[12, 0]}>
            <InvoiceFormControl
              type="input-number"
              span={6}
              form={{
                label: t('invoiceGenerator.addItem.fieldPrice.label')(),
                name: 'unitPrice',
                rules: [{ required: true, message: ' ' }],
              }}
            />

            <InvoiceFormControl
              type="input-number-inline"
              span={7}
              form={{
                label: t('invoiceGenerator.addItem.fieldQuantity.label')(),
                name: 'quantity',
                rules: [{ required: true }],
              }}
              control={{
                type: 'inline',
                min: 0,
              }}
            />

            <InvoiceFormControl
              type="select"
              span={6}
              form={{
                label: t('invoiceGenerator.addItem.fieldUnit.label')(),
                name: 'unit',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.addItem.fieldUnit.placeholder',
                )(),
                options: [
                  {
                    label: t(
                      'invoiceGenerator.addItem.fieldUnit.options.day',
                    )(),
                    value: InvoiceItemUnit.Day,
                  },
                  {
                    label: t(
                      'invoiceGenerator.addItem.fieldUnit.options.month',
                    )(),
                    value: InvoiceItemUnit.Month,
                  },
                  {
                    label: t(
                      'invoiceGenerator.addItem.fieldUnit.options.item',
                    )(),
                    value: InvoiceItemUnit.Item,
                  },
                ],
              }}
            />

            <InvoiceFormControl
              type="select"
              span={5}
              form={{
                label: t('invoiceGenerator.addItem.fieldRate.label')(),
                name: 'taxRate',
                rules: [{ required: true }],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.addItem.fieldRate.placeholder',
                )(),
                options: VATPercents.map((percent) => ({
                  label: `${percent}%`,
                  value: percent / 100,
                })),
              }}
            />
          </Row>

          {values?.taxRate === 0 ||
          values?.taxRate === helpers.middlePercent ? (
              <S.AlertWarning
                type="warning"
                description={
                  <>
                    <S.AlertWarningTitle>
                      {t('invoiceGenerator.addItem.alertWarning.title')()}
                    </S.AlertWarningTitle>

                    <Trans
                      i18nKey="invoiceGenerator.addItem.alertWarning.description"
                      components={[
                        <a
                          href="https://guichet.public.lu/en/entreprises/fiscalite/impots-benefices/tva/notions.html"
                          target="_blank"
                        />,
                      ]}
                    />
                  </>
                }
              />
            ) : null}

          {values?.taxRate === 0 ? (
            <InvoiceFormControl
              type="select"
              form={{
                label: t('invoiceGenerator.addItem.fieldZeroVATReason.label')(),
                name: ['settings', 'zeroVATReason'],
                rules: [{ required: true }],
              }}
              control={{
                placeholder: t(
                  'invoiceGenerator.addItem.fieldZeroVATReason.placeholder',
                )(),
                labelRender: ({ label }) =>
                  helpers.getTranslatesOptionByZeroVATReason(label).title,
                optionRender: ({ label }) => (
                  <S.Option gap={10} vertical>
                    <S.OptionTitle>
                      {helpers.getTranslatesOptionByZeroVATReason(label).title}
                    </S.OptionTitle>

                    <S.OptionValue>
                      {
                        helpers.getTranslatesOptionByZeroVATReason(label)
                          .description
                      }
                    </S.OptionValue>
                  </S.Option>
                ),
                options: [
                  {
                    label: 'exempt',
                    value: InvoiceZeroVATReason.Exempt,
                  },
                  {
                    label: 'intraCommunityReverseCharge',
                    value: InvoiceZeroVATReason.IntraCommunityReverseCharge,
                  },
                  {
                    label: 'exportFrom',
                    value: InvoiceZeroVATReason.ExportFromEu,
                  },
                ],
              }}
            />
          ) : null}

          <S.Switch gap={12}>
            <Form.Item
              name={['settings', 'isUnitPriceWithTaxInluded']}
              valuePropName="checked"
              noStyle
            >
              <Switch />
            </Form.Item>

            <span>
              {t('invoiceGenerator.addItem.fieldIncludedPrice.label')()}
            </span>
          </S.Switch>
        </InvoiceFormCard>

        {type === 'add' && (
          <S.Total align="center" justify="flex-end" gap={12}>
            <span>{t('invoiceGenerator.addItem.total')()}:</span>
            <Amount
              currencyCode={currency || DEFAULT_CURRENCY_CODE}
              amount={helpers.getTotalAmount(values)}
            />
          </S.Total>
        )}

        {type === 'edit' && (
          <S.Total align="center" justify="space-between">
            <span>{t('invoiceGenerator.addItem.total')()}:</span>
            <Amount
              currencyCode={currency || DEFAULT_CURRENCY_CODE}
              amount={helpers.getTotalAmount(values)}
            />
          </S.Total>
        )}

        <S.Submit>
          <Flex
            justify={type === 'add' ? 'flex-end' : 'space-between'}
            align="center"
          >
            {type === 'edit' && item && (
              <Button
                type="primary"
                icon={<IconTrash />}
                onClick={() => handleRemoveItem(item.id!)}
                danger
              >
                {t('invoiceGenerator.addItem.buttonDelete')()}
              </Button>
            )}

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={onCancel}>
                  {t('invoiceGenerator.buttonCancel')()}
                </Button>

                <Button type="primary" htmlType="submit">
                  {t('invoiceGenerator.buttonSave')()}
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </S.Submit>
      </Form>
    </InvoiceModalWrapper>
  );
};

export default InvoiceModalItemAction;
