import { Flex } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { EasyBizLogo } from '@assets';
import styled from '@emotion/styled';

const Task = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isGeneral',
})<{ isSelected: boolean; isGeneral?: boolean }>`
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.white};
  border-bottom: ${({ isSelected, isGeneral, theme }) =>
    !isSelected && !isGeneral && `1px solid ${theme.colors.darkgrey}`};
  border: ${({ isSelected, isGeneral, theme }) =>
    !isSelected && isGeneral && `1px solid ${theme.colors.darkgrey}`};
  cursor: pointer;
  padding: 16px;
  border-radius: ${({ isGeneral }) => isGeneral && '10px'};
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  min-height: 76px;
`;

const Title = styled.div<{ isInversed: boolean }>`
  overflow: hidden;
  color: ${({ isInversed, theme }) =>
    isInversed ? theme.colors.white : theme.colors.dark};
  text-overflow: ellipsis;
`;

const AccessType = styled.p<{
  isOpen: boolean;
  isInversed: boolean;
  isGeneral?: boolean;
}>`
  color: ${({ isOpen, isInversed, isGeneral, theme }) =>
    isInversed
      ? theme.colors.bgDarkGrey
      : isGeneral
        ? theme.colors.text400
        : isOpen
          ? theme.colors.success
          : theme.colors.text200};
  font-size: 12px;
  font-weight: ${({ isGeneral }) => (isGeneral ? 'normal' : '600')};
  line-height: 20px;
`;

const Type = styled.div<{ isInversed: boolean }>`
  color: ${({ isInversed, theme }) =>
    isInversed ? theme.colors.bgDarkGrey : theme.colors.text200};
  font-size: 10px;
  line-height: 20px;
`;

const Count = styled.span<{ isInversed: boolean }>`
  min-width: 18px;
  height: 18px;
  padding: 2px 5px 0 5px;
  color: ${({ isInversed, theme }) =>
    isInversed ? theme.colors.primary : theme.colors.white};
  background-color: ${({ isInversed, theme }) =>
    isInversed ? theme.colors.white : theme.colors.primary};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
`;

const Logo = styled(EasyBizLogo)`
  min-width: 44px;
  max-width: 44px;
  height: 44px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey400};
  border-radius: 50%;
  padding: 6px;
`;

type TaskItemProps = {
  isSelected: boolean;
  isGeneral?: boolean;
  details: Schemas.Task;
};

const Item: FC<TaskItemProps> = ({ isSelected, details, isGeneral }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const handleSelectTask = (id: string) => {
    navigate(`/tasks/${id}${search}`);
  };

  const isInversed = isSelected;
  return (
    <Task
      isSelected={isInversed}
      isGeneral={isGeneral}
      onClick={() => handleSelectTask(details.id)}
    >
      {isGeneral && <Logo />}
      <div>
        <Flex align="center" justify="space-between" gap={16}>
          <Title isInversed={isInversed}>
            {isGeneral ? t('chat.generalTitle')() : details.title}
          </Title>

          {!!details.unseenMessagesCount && (
            <Count isInversed={isInversed}>{details.unseenMessagesCount}</Count>
          )}
        </Flex>

        <Flex align="center" justify="space-between" gap={16}>
          <AccessType
            isOpen={!details.closedAt}
            isInversed={isInversed}
            isGeneral={isGeneral}
          >
            {t(
              isGeneral
                ? 'chat.generalHint'
                : details.closedAt
                  ? 'chat.resolvedTask'
                  : 'chat.openTask'
            )()}
          </AccessType>

          <Type isInversed={isInversed}>
            {dayjs(details.updatedAt).format('HH:mm')}
          </Type>
        </Flex>
      </div>
    </Task>
  );
};

export default Item;
