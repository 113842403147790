import { forwardRef } from 'react';

import styled from '@emotion/styled';

const Panel = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 10px;
  line-height: 20px;
  text-align: center;
  margin: 14px 0;
  border-radius: 5px;
  background: ${({ theme }) => theme.colors.darkgrey};
  width: 100%;
  scroll-margin-top: 22px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const UnreadDivider = forwardRef<HTMLDivElement>((_, ref) => (
  <Panel ref={ref}>{t('chatLive.unreadMessages')()}</Panel>
));

export default UnreadDivider;
