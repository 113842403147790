import { Flex, type TableColumnType } from 'antd';
import _ from 'lodash';

import { Amount } from '@entities';
import { Schemas } from '@api-client/generated/types';
import {
  DEFAULT_CURRENCY_CODE,
  InvoiceZeroVATReason,
  VAT_DEFAULT_PERCENT,
  VAT_MIDDLE_PERCENT,
} from '@constants';

import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

const defaultPercent = VAT_DEFAULT_PERCENT / 100;
const middlePercent = VAT_MIDDLE_PERCENT / 100;

export const getTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(items.map((item) => item.totalPrice)).toFixed(2);

export const getSubTotalAmount = (items: InvoiceItem[]): number =>
  +_.sum(items.map((item) => item.unitPrice * item.quantity)).toFixed(2);

export const getItemsByDefaultTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === defaultPercent);

export const getItemsByMiddleTaxRate = (items: InvoiceItem[]) =>
  items.filter((item) => item.taxRate === middlePercent);

export const getAmountByDefaultTaxRate = (items: InvoiceItem[]): number => {
  const itemsByDefaultTaxRate = getItemsByDefaultTaxRate(items);

  return +_.sum(
    itemsByDefaultTaxRate.map(
      (item) => item.unitPrice * item.quantity * item.taxRate,
    ),
  ).toFixed(2);
};

export const getAmountByMiddleTaxRate = (items: InvoiceItem[]): number => {
  const itemsByMiddleTaxRate = getItemsByMiddleTaxRate(items);

  return +_.sum(
    itemsByMiddleTaxRate.map(
      (item) => item.unitPrice * item.quantity * item.taxRate,
    ),
  ).toFixed(2);
};

export const hasIntraEU = (items: InvoiceItem[] = []): boolean =>
  items.some(
    (item) =>
      item.settings?.zeroVATReason ===
      InvoiceZeroVATReason.IntraCommunityReverseCharge,
  );

export const getTableColumns = (
  currency: Schemas.DocumentMetadata['currency'] = DEFAULT_CURRENCY_CODE,
): TableColumnType<Schemas.InvoiceItem>[] => [
  {
    key: 'description',
    dataIndex: 'description',
    title: t('invoiceGenerator.document.tableColumns.description')(),
    render: (description, { name }) => (
      <Flex vertical>
        <S.Name>{name}</S.Name>
        <span>{description}</span>
      </Flex>
    ),
  },
  {
    key: 'unitPrice',
    dataIndex: 'unitPrice',
    title: t('invoiceGenerator.document.tableColumns.price')(),
    align: 'right',
    render: (amount) => (
      <Amount
        currencyCode={currency || DEFAULT_CURRENCY_CODE}
        amount={amount}
      />
    ),
  },
  {
    key: 'taxRate',
    dataIndex: 'taxRate',
    title: t('invoiceGenerator.document.tableColumns.rate')(),
    align: 'right',
    render: (rate) => `${(rate || 0) * 100}%`,
  },
  {
    key: 'quantity',
    dataIndex: 'quantity',
    title: t('invoiceGenerator.document.tableColumns.quantity')(),
    align: 'right',
    render: (quantity, { unit }) => `${quantity} ${unit}`,
  },
  {
    key: 'totalPrice',
    dataIndex: 'totalPrice',
    title: t('invoiceGenerator.document.tableColumns.total')(),
    align: 'right',
    render: (amount) => (
      <Amount
        currencyCode={currency || DEFAULT_CURRENCY_CODE}
        amount={amount}
      />
    ),
  },
];
