import { FC } from 'react';

import { colors } from '@theme';

import * as S from './styled';

type Status =
  | 'primary'
  | 'inProgress'
  | 'pending'
  | 'connected'
  | 'completed'
  | 'disconnected'
  | 'active'
  | 'inactive'
  | 'manually'
  | 'automatically'
  | 'error'
  | 'errorsFound'
  | 'onReview'
  | 'finished'
  | 'near_to_disconnect'
  | 'draft'
  | 'paid'
  | 'unpaid'
  | 'due'
  | 'overdue'
  | 'archived';

type StatusTagProps = {
  label?: string | null;
  status?: Status | string | null;
  small?: boolean;
  additionalData?: Record<string, unknown>;
};

type ParamsByStatus = {
  label?: string | null;
  bgColor: string;
  textColor: string;
};

const StatusTag: FC<StatusTagProps> = ({
  small,
  label,
  status,
  additionalData,
}) => {
  if (!status) return;

  const getParamsByStatus = (status: Status): ParamsByStatus => {
    switch (status) {
    case 'primary':
    case 'inProgress':
    case 'onReview':
    case 'pending':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.bgBrightBlue,
        textColor: colors.primary,
      };

    case 'inactive':
    case 'near_to_disconnect':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.badgeInfo,
        textColor: colors.iconLineYellow,
      };

    case 'connected':
    case 'automatically':
    case 'completed':
    case 'active':
    case 'paid':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.badgeSuccess,
        textColor: colors.success,
      };

    case 'archived':
    case 'finished':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.bgGrey,
        textColor: colors.text400,
      };

    case 'manually':
    case 'draft':
    case 'due':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.badgeGrey,
        textColor: colors.text200,
      };

    case 'error':
    case 'errorsFound':
    case 'disconnected':
    case 'overdue':
      return {
        label: t(`common.statuses.${status}`)(),
        bgColor: colors.bgWarning,
        textColor: colors.error,
      };

    case 'unpaid': {
      return {
        label: t(`common.statuses.${status}`, additionalData)(),
        bgColor: colors.bgWarning,
        textColor: colors.error,
      };
    }

    default:
      return {
        label,
        bgColor: colors.bgGrey,
        textColor: colors.textBlue400,
      };
    }
  };

  const params = getParamsByStatus(status as Status);

  return (
    <S.Status
      small={small}
      bgColor={params.bgColor}
      textColor={params.textColor}
    >
      {label || params.label}
    </S.Status>
  );
};

export default StatusTag;
