import { Input } from 'antd';
import { FC } from 'react';

import * as S from './styled';

type InvoiceDocumentFieldEditorProps = {
  defaultValue: string | null;
  label: string;
  placeholder?: string;
  onChange: (value: string) => void;
};

const InvoiceDocumentFieldEditor: FC<InvoiceDocumentFieldEditorProps> = ({
  defaultValue,
  label,
  placeholder,
  onChange,
}) => (
  <S.Control gap={8} vertical>
    <S.ControlLabel>{label}</S.ControlLabel>

    <Input.TextArea
      defaultValue={defaultValue || ''}
      variant="borderless"
      placeholder={
        placeholder || t('invoiceGenerator.document.fieldNotes.placeholder')()
      }
      onChange={(e) => onChange(e.target.value)}
      autoSize
    />
  </S.Control>
);

export default InvoiceDocumentFieldEditor;
