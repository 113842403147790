import { TreeSelect } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { FC, useState } from 'react';

import { Select, type SelectProps } from '@components';
import { useAccount, useExtractCategoryGroups } from '@hooks';
import { selectFilterOption } from '@utils';
import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { useCategoryGroupController_findAll } from '@api-client/generated/CategoryGroupController/findAll';
import { useContactsController_findAll } from '@api-client/generated/ContactsController/findAll';
import { useProjectController_findAll } from '@api-client/generated/ProjectController/findAll';

import * as S from './styled';

const { SHOW_PARENT } = TreeSelect;

type FilterCategory = SelectProps & {
  label?: string;
  dataVariant?:
    | 'contactsSelect'
    | 'categorySelect'
    | 'accountsSelect'
    | 'projectsSelect'
    | '';
  isFiltersModalOpen?: boolean;
};

/** @deprecated don't use it, make separate selectors instead */
const FilterCategory: FC<FilterCategory> = ({
  placeholder,
  onChange,
  label,
  dataVariant,
  value = [],
  isFiltersModalOpen,
  ...rest
}) => {
  const { companyId } = useAccount();

  const isQueryEnabled = (variant: FilterCategory['dataVariant']) =>
    isFiltersModalOpen && dataVariant === variant;

  // WTF this queries
  const { data: categoryGroups } = useCategoryGroupController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isQueryEnabled('categorySelect'),
    },
  });

  const { data: contacts } = useContactsController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isQueryEnabled('contactsSelect'),
    },
  });

  const { data: projects } = useProjectController_findAll({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isQueryEnabled('projectsSelect'),
    },
  });

  const { data: accounts } = useAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
    config: {
      enabled: isQueryEnabled('accountsSelect'),
    },
  });

  const { extractedGroupsWithChildren, findById } =
    useExtractCategoryGroups(categoryGroups);

  const [searchValue, setSearchValue] = useState('');

  const getData = () => {
    if (dataVariant) {
      switch (dataVariant) {
      case 'categorySelect':
        return extractedGroupsWithChildren.map((group) => ({
          title: group.title,
          value: [group.id, group.options?.map((option) => option.id)].join(
            ',',
          ),
          children: group.options,
        }));
      case 'contactsSelect':
        return (contacts?.records || []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
      case 'projectsSelect':
        return (projects || []).map((item) => ({
          label: item.name,
          value: item.id,
        }));
      case 'accountsSelect':
        return (accounts || []).map((item) => ({
          label: item.name || item.accountName,
          value: item.id,
        }));
      default:
        return [];
      }
    } else return [];
  };

  const getSelectedItemsCountDescription = () => {
    switch (dataVariant) {
    case 'categorySelect':
      return t('common.filterCategory.counts.categories')();
    case 'contactsSelect':
      return t('common.filterCategory.counts.contacts')();
    case 'accountsSelect':
      return t('common.filterCategory.counts.banks')();
    case 'projectsSelect':
      return t('common.filterCategory.counts.projects')();
    default:
      return t('common.filterCategory.counts.categories')();
    }
  };

  const renderValue = (props: CustomTagProps) => {
    const { label } = props;
    return value.length === 1 ? <S.SingleValue>{label}</S.SingleValue> : <></>;
  };

  const displayedValue =
    value.length > 1 && !searchValue.length
      ? `${value.length} ${getSelectedItemsCountDescription()}`
      : '';

  const filterSort = (
    firstOption: DefaultOptionType,
    secondOption: DefaultOptionType,
  ) => {
    if (
      typeof firstOption?.label === 'string' &&
      typeof secondOption?.label === 'string'
    ) {
      return (firstOption?.label ?? '')
        ?.toLowerCase()
        .localeCompare((secondOption?.label ?? '').toLowerCase());
    }
    return 0;
  };

  const handleCategoryChange = (value: string[]) => {
    // WTF for some reason instead of array of string value is an array of one concatenated element
    const id =
      value[0] && value[0].includes(',') ? value[0].split(',')[0] : value[0];
    const option = findById(id);
    onChange?.(value, option || []);
    setSearchValue('');
  };

  const handleChange: Parameters<typeof Select>[0]['onChange'] = (
    value,
    option,
  ) => {
    onChange?.(value, option);
    setSearchValue('');
  };

  return (
    <S.SelectContainer>
      <S.Label>{label}</S.Label>
      {dataVariant === 'categorySelect' ? (
        <S.SelectTree
          className="ui-category-filter-select"
          treeData={getData()}
          value={value}
          onChange={handleCategoryChange}
          showCheckedStrategy={SHOW_PARENT}
          placeholder={placeholder}
          treeNodeFilterProp="label"
          treeCheckable
        />
      ) : (
        <>
          <Select
            className="ui-category-filter-select"
            filterOption={selectFilterOption}
            filterSort={filterSort}
            searchValue={searchValue}
            onSearch={setSearchValue}
            mode="multiple"
            size="large"
            defaultValue={value}
            value={value}
            tagRender={renderValue}
            onChange={handleChange}
            options={getData()}
            placeholder={placeholder}
            {...rest}
          />
          {value.length > 1 && (
            <S.DisplayedValue>{displayedValue}</S.DisplayedValue>
          )}
        </>
      )}
    </S.SelectContainer>
  );
};

export default FilterCategory;
