import { Flex, Form, type ModalProps } from 'antd';
import { FC, useEffect } from 'react';

import { Button } from '@ui-kit/Button/Button';
import { Schemas } from '@api-client/generated/types';

import InvoiceFormCard from '../../form/InvoiceFormCard';
import InvoiceFormControl from '../../form/InvoiceFormControl';
import InvoiceModalWrapper from '../InvoiceModalWrapper';
import * as S from './styled';

type DetailsDto = Schemas.CompanyDetailsDto;

type InvoiceModalContactActionProps = {
  mode: 'add' | 'edit';
  details: Schemas.CompanyDetails;
  onSubmit: (values: Schemas.CompanyDto) => void;
  onCancel: VoidFunction;
} & ModalProps;

const InvoiceModalContactAction: FC<InvoiceModalContactActionProps> = ({
  mode,
  details,
  open,
  onSubmit,
  onCancel,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (details?.contactPerson) {
      form.setFieldsValue({ ...details.contactPerson });
    }
  }, [form, details]);

  const handleSubmit = (values: DetailsDto) => {
    onSubmit({
      details: {
        ...details,
        contactPerson: {
          ...details.contactPerson,
          ...values,
        },
      },
    } as DetailsDto);
  };

  return (
    <InvoiceModalWrapper
      open={open}
      title={
        <S.Title>
          {mode === 'add'
            ? t('invoiceGenerator.actionContact.title')()
            : t('invoiceGenerator.actionContact.titleEdit')()}
        </S.Title>
      }
      onCancel={onCancel}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <InvoiceFormCard>
          <InvoiceFormControl
            type="input"
            form={{
              label: t('invoiceGenerator.actionContact.fieldEmail.label')(),
              name: 'email',
              rules: [{ type: 'email' }],
            }}
          />

          <InvoiceFormControl
            type="input"
            form={{
              label: t(
                'invoiceGenerator.actionContact.fieldPhoneNumber.label',
              )(),
              name: 'phone',
            }}
          />
        </InvoiceFormCard>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={onCancel}>
                {t('invoiceGenerator.buttonCancel')()}
              </Button>

              <Button type="primary" htmlType="submit">
                {mode === 'add'
                  ? t('invoiceGenerator.buttonAdd')()
                  : t('invoiceGenerator.buttonSave')()}
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </InvoiceModalWrapper>
  );
};

export default InvoiceModalContactAction;
