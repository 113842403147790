import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Flex, Typography } from 'antd';
import { useState } from 'react';

import {
  BillingAddCardDrawer,
  BillingCurrentPeriod,
  BillingHistory,
  BillingHistoryEmailModal,
  BillingPaymentDetails,
  PageMeta,
} from '@entities';
import { useAccount } from '@hooks';
import { useGetCompanyById } from '@hooks/api';
import { useBillingPeriodController_current } from '@api-client/generated/BillingPeriodController/current';
import { usePaymentMethodController_findOne } from '@api-client/generated/PaymentMethodController/findOne';
import { usePaymentMethodController_intialize } from '@api-client/generated/PaymentMethodController/intialize';

const { Title } = Typography;

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

const SettingsBilling = () => {
  const [isAddCardOpen, setIsAddCardOpen] = useState(false);
  const [isEditEmailOpen, setEditEmailOpen] = useState(false);
  const { companyId } = useAccount();

  const { mutateAsync: init, data: clientSecret } =
    usePaymentMethodController_intialize();

  const { data: company, refetch: refetchCompany } = useGetCompanyById({
    params: {
      companyId: companyId!,
    },
  });

  const {
    data: currentPaymentMethod,
    isPending: isCurrentPaymentMethodLoading,
    refetch: refetchCurrentPayment,
  } = usePaymentMethodController_findOne({
    params: {
      companyId: companyId!,
    },
  });

  const {
    data: currentBillingPeriod,
    isPending: isCurrentBillingPeriodLoading,
  } = useBillingPeriodController_current({
    params: {
      companyId: companyId!,
    },
  });

  const handleAddCard = async () => {
    if (!companyId) return;

    await init({
      parameter: {
        companyId,
      },
    });
    setIsAddCardOpen(true);
  };

  const handleAddCardClose = async () => {
    setIsAddCardOpen(false);
    await refetchCurrentPayment();
  };

  const handleCloseEmailModal = async () => {
    setEditEmailOpen(false);
    await refetchCompany();
  };

  return (
    <>
      <Flex gap={16} vertical>
        <PageMeta title={t('settings.billing.title')()} />

        <Title>{t('settings.billing.title')()}</Title>

        <Flex gap={40}>
          <Flex flex="2 0" gap={40} vertical>
            <BillingPaymentDetails
              isLoading={isCurrentPaymentMethodLoading}
              emails={company?.billingEmails || []}
              paymentMethod={currentPaymentMethod}
              onCardEditOpenClick={handleAddCard}
              onChangeEmailClick={() => setEditEmailOpen(true)}
            />

            <BillingHistory />
          </Flex>

          <Flex flex="1 0">
            <BillingCurrentPeriod
              isLoading={isCurrentBillingPeriodLoading}
              currentPeriod={currentBillingPeriod}
            />
          </Flex>
        </Flex>
      </Flex>

      <BillingHistoryEmailModal
        isOpen={isEditEmailOpen}
        onClose={handleCloseEmailModal}
      />

      {clientSecret && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
          }}
        >
          <BillingAddCardDrawer
            hasCard={Boolean(currentPaymentMethod)}
            clientSecret={clientSecret}
            open={isAddCardOpen}
            onClose={handleAddCardClose}
          />
        </Elements>
      )}
    </>
  );
};

export default SettingsBilling;
