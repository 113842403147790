import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const animation = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
`;

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: 100%;
  flex: 1 1 100%;
  border: 1px solid ${({ theme }) => theme.colors.grey200};
  border-radius: 10px;
`;

export const Table = styled.table`
  display: grid;
  width: 100%;
`;

export const Thead = styled.thead`
  display: grid;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
`;

export const TBody = styled.tbody`
  display: grid;
  position: relative;
`;

export const TRow = styled.tr`
  cursor: pointer;
  display: flex;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.grey200};
  }

  &:hover {
    background-color: #eee;
  }
`;

export const Th = styled.th`
  padding: 16px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Td = styled.td`
  display: flex;
  align-items: center;
  justify-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TdText = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 16px;
`;

export const Skeleton = styled.div`
  width: 100%;
  height: 50%;
  margin: 0 16px;
  align-self: center;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 4px;

  animation: ${animation} 1.5s infinite alternate;
`;

export const Icon = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey500};
  opacity: ${({ active }) => (active === false ? 0.35 : 1)};
`;
