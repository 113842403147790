import { Flex } from 'antd';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IconTrash } from '@assets';
import { PopupDelete } from '@entities';
import { useAccount } from '@hooks';
import { useDocumentController_delete } from '@api-client/generated/DocumentController/delete';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { currencyCodes, DEFAULT_CURRENCY_CODE } from '@constants';

import InvoicePDFMake from '../../pdfmake/InvoicePDFMake';
import InvoiceFormControl from '../InvoiceFormControl';
import * as S from './styled';

type Currency = Schemas.DocumentMetadata['currency'];

type InvoiceFormProps = {
  document: Schemas.Document;
  company: Schemas.Company;
  onAfterUpdate: VoidFunction;
};

const InvoiceForm: FC<InvoiceFormProps> = ({
  company,
  document,
  onAfterUpdate,
}) => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();
  const { companyId } = useAccount();

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency>(
    document.documentMetadata?.currency || DEFAULT_CURRENCY_CODE,
  );

  const { mutate: updateDocument } = useDocumentController_updateOneById();
  const { mutate: deleteDocument } = useDocumentController_delete();

  const handleUpdateDocument = (value: Currency) => {
    setSelectedCurrency(value);

    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id: documentId!,
        },
        requestBody: {
          documentMetadata: {
            ...document.documentMetadata,
            currency: value,
          },
        } as Schemas.UpdateDocumentDto,
      },
      {
        onSuccess: () => onAfterUpdate(),
      },
    );
  };

  const handleDeleteDraft = () => {
    deleteDocument(
      {
        parameter: {
          companyId: companyId!,
          id: documentId!,
        },
      },
      {
        onSuccess: () => navigate('/income'),
      },
    );
  };

  return (
    <Flex gap={16} vertical>
      <PopupDelete
        open={isOpenPopupConfirm}
        title={t('invoiceGenerator.deleteConfirm.title')()}
        confirmParams={{
          text: t('invoiceGenerator.deleteConfirm.buttonConfirm')(),
        }}
        onRemove={handleDeleteDraft}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />

      <S.Card>
        <S.Title>{t('invoiceGenerator.draft.title')()}</S.Title>

        <InvoiceFormControl
          type="select"
          form={{
            label: t('invoiceGenerator.draft.fieldCurrency.label')(),
            layout: 'vertical',
          }}
          control={{
            placeholder: t(
              'invoiceGenerator.draft.fieldCurrency.placeholder',
            )(),
            options: currencyCodes.map((code) => ({
              label: code,
              value: code,
            })),
            value: selectedCurrency,
            onChange: handleUpdateDocument,
          }}
        />

        <InvoicePDFMake document={document} company={company} />
      </S.Card>

      <S.ButtonDelete
        type="text"
        icon={<IconTrash />}
        onClick={() => setIsOpenPopupConfirm(true)}
        danger
        block
      >
        {t('invoiceGenerator.draft.buttonDeleteDraft')()}
      </S.ButtonDelete>
    </Flex>
  );
};

export default InvoiceForm;
