import { PropsWithChildren, useCallback, useRef, useState } from 'react';

import { PopupConfirm } from '@entities';

import {
  ConfirmationContext,
  type OpenConfirmationConfig,
} from './ConfirmationContext';

type ConfirmationProviderProps = {} & PropsWithChildren;

function ConfirmationProvider(props: ConfirmationProviderProps) {
  const [isOpened, setIsOpened] = useState(false);
  const [config, setConfig] = useState<null | OpenConfirmationConfig>(null);
  const answerPromise = useRef<null | { resolve: (v: boolean) => void }>(null);

  const openConfirmation = useCallback((config: OpenConfirmationConfig) => {
    setConfig(config);
    setIsOpened(true);
    return new Promise<boolean>((resolve) => {
      answerPromise.current = { resolve };
    });
  }, []);

  function closeAndReset() {
    setIsOpened(false);
    setConfig(null);
    answerPromise.current = null;
  }

  const handleConfirm = useCallback(() => {
    if (answerPromise.current) answerPromise.current.resolve(true);
    closeAndReset();
  }, []);

  const handleCancel = useCallback(() => {
    if (answerPromise.current) answerPromise.current.resolve(false);
    closeAndReset();
  }, []);

  return (
    <ConfirmationContext.Provider
      value={{
        close: handleCancel,
        open: openConfirmation,
      }}
    >
      {props.children}

      <PopupConfirm
        open={isOpened}
        title={config?.title || 'Are you sure?'}
        description={config?.description}
        confirmParams={
          config?.confirmParams || { text: t('common.actions.confirm')() }
        }
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </ConfirmationContext.Provider>
  );
}

export default ConfirmationProvider;
