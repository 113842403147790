//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$AccountsController_getAccounts,
  ResponseContentType$AccountsController_getAccounts,
  Response$AccountsController_getAccounts$Status$200,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';
export const useAccountsController_getAccounts = (options: {
  params: Params$AccountsController_getAccounts['parameter'];
  config?: QueryOptions<
    Response$AccountsController_getAccounts$Status$200[ResponseContentType$AccountsController_getAccounts]
  >;
}) =>
  useApiClient<
    Params$AccountsController_getAccounts,
    Response$AccountsController_getAccounts$Status$200[ResponseContentType$AccountsController_getAccounts]
  >({
    method: 'AccountsController_getAccounts',
    params: { parameter: options.params },
    config: options.config,
  });
