import styled from '@emotion/styled';
import { Flex, type FlexProps } from 'antd';

export const Content = styled(Flex)<FlexProps & { width?: number }>`
  width: ${({ width }) => `${width || 327}px`};
`;

export const Inner = styled.div`
  padding: 8px;
`;

export const Projects = styled.div`
  margin: 0 -12px 16px -12px;
`;

export const Item = styled('div', {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  background: ${({ selected, theme }) =>
    selected ? theme.colors.darkgrey : 'transparent'};

  &:hover {
    background: ${({ theme }) => theme.colors.darkgrey};
  }
`;
export const ItemName = styled.span`
  color: ${({ theme }) => theme.colors.black};
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemCount = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.text400};
  margin-bottom: 8px;
`;
