import { Button, Flex } from 'antd';

import { IconEdit } from '@assets';
import Amount from '@entities/common/Amount';
import { DEFAULT_CURRENCY_CODE } from '@constants';

import { Project } from '..';
import * as S from './styled';

type ProjectCardDetailsProps = {
  project: Project;
  onEdit: () => void;
};

const ProjectCardDetails = (props: ProjectCardDetailsProps) => (
  <S.DetailsCard>
    <Flex vertical gap={20}>
      <Flex justify="space-between">
        <S.ProjectNameWrapper gap={4} vertical>
          <S.Label>{t('projects.properties.name')()}</S.Label>
          <S.ProjectName>{props.project.name}</S.ProjectName>
        </S.ProjectNameWrapper>

        <Button
          type="text"
          icon={<IconEdit />}
          size="small"
          onClick={props.onEdit}
        >
          {t('projects.buttons.edit')()}
        </Button>
      </Flex>

      {props.project.notes && (
        <Flex gap={4} vertical>
          <S.Label>{t('projects.properties.description')()}</S.Label>
          {props.project.notes}
        </Flex>
      )}

      <Flex gap={4} vertical>
        <S.Label>{t('projects.properties.income')()}</S.Label>
        <Amount
          amount={props.project.totalIncomingAmount}
          currencyCode={DEFAULT_CURRENCY_CODE}
        />
      </Flex>

      <Flex gap={4} vertical>
        <S.Label>{t('projects.properties.expenses')()}</S.Label>
        <Amount
          amount={props.project.totalOutgoingAmount}
          currencyCode={DEFAULT_CURRENCY_CODE}
        />
      </Flex>

      <Flex gap={4} vertical>
        <S.Label>{t('projects.properties.total')()}</S.Label>
        <Amount
          amount={props.project.totalAmount}
          currencyCode={DEFAULT_CURRENCY_CODE}
        />
      </Flex>
    </Flex>
  </S.DetailsCard>
);

export default ProjectCardDetails;
