//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$PaymentMethodController_finalize,
  ResponseContentType$PaymentMethodController_finalize,
  Response$PaymentMethodController_finalize$Status$201,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const usePaymentMethodController_finalize = () =>
  useApiClientMutation<
    Params$PaymentMethodController_finalize,
    Response$PaymentMethodController_finalize$Status$201[ResponseContentType$PaymentMethodController_finalize]
  >({ method: 'PaymentMethodController_finalize' });
