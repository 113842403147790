import { IconBook } from '@assets';

import * as S from './styled';

const ProjectsListEmpty = () => (
  <S.Wrapper>
    <S.Icon>
      <IconBook />
    </S.Icon>

    <S.Title level={3}>{t('projects.emptyList.title')()}</S.Title>
  </S.Wrapper>
)

export default ProjectsListEmpty;
