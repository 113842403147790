import { TransactionsTable, TransactionsTableFilterForm } from "@entities/transactions/TransactionsTable";

import { Project } from "..";


const ProjectCardTransactions = (props: { project: Project }) => (
  <TransactionsTable
    hiddenColumns={['project']}
    queryParams={{
      projectIds: [props.project.id],
    }}
    filterForm={({ onFormUpdate, total }) => (
      <TransactionsTableFilterForm
        total={total}
        isDarkTheme
        hiddenFormFields={new Set(['projectIds', 'date'])}
        onFormUpdate={onFormUpdate}
        queryParams={{
          projectIds: [props.project.id],
        }}
      />
    )}
  />
)

export default ProjectCardTransactions;