class ProjectListLocators {
  list = 'project-list'
  listItem = `${this.list}-item`
  card = 'project-card'
  cardName = `${this.card}-name`
  cardStatus = `${this.card}-status`
  cardDetails = `${this.card}-details`
  searchControl = 'search-control'
};

/** Constants and etc to be used outside of e2e tests */
export const e2e = {
  locators: {
    projectsPage: new ProjectListLocators(),
  },
};
