import type { ThemeConfig } from 'antd';
import { rgba } from 'emotion-rgba';

import { colorPalette } from '../';

const cardTokens: ThemeConfig['components'] = {
  Card: {
    borderRadiusLG: 16,
    paddingLG: 32,
    boxShadowTertiary: `0 3px 8px 0 ${rgba(colorPalette.baseBlack, 0.1)}`,
  },
};

export default cardTokens;
