import { Spin } from 'antd';
import { useMemo } from 'react';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { AccessDenied } from '@entities/errorBoundary/AccessDenied';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { useAccount } from '@hooks';
import { LayoutFullPage } from '@layouts/LayoutFullPage';
import { LayoutProtected } from '@layouts/LayoutProtected';
import { LayoutPublic } from '@layouts/LayoutPublic';
import {
  AcceptInvitation,
  Accounts,
  Categories,
  ConfirmEmail,
  ConnectBank,
  Contacts,
  CSVImports,
  DocumentDetails,
  Documents,
  Expenses,
  ForgotPassword,
  ForgotPasswordMessage,
  Income,
  InvoiceGenerator,
  Legal,
  Login,
  NotFound,
  Onboarding,
  Payroll,
  Payslips,
  PrivacyPolicy,
  Projects,
  Report,
  ResetPassword,
  Settings,
  SettingsBilling,
  SettingsCompany,
  SettingsPersonal,
  SettingsTeamMembers,
  Taxes,
  TaxesDetails,
  TransactionDetails,
  Transactions,
  TransactionsImport,
} from '@pages';
import { Rules } from '@pages/Rules/Rules';
import { Tasks } from '@pages/Tasks/Tasks';
import { filteredRoutes } from '@utils';

function DefaultErrorComponent({ fullPage }: { fullPage?: boolean }) {
  const error = useRouteError();
  const navigate = useNavigate();
  const stack = error instanceof Error ? error.stack : undefined;

  return (
    <ErrorFallback
      error={error}
      componentStack={stack}
      fullPage={fullPage}
      onClick={() => navigate(-1)}
    />
  );
}

const createRoutes = (
  isLogged: boolean,
  userAccess: Schemas.AccessRights | null,
) => {
  const appRoutes: RouteObject[] = [
    {
      path: '/',
      element: <LayoutProtected />,
      children: [
        {
          path: '/accounts',
          element: <Accounts />,
        },
        {
          path: '/documents',
          element: <Documents />,
        },
        {
          path: '/documents/:id',
          element: <DocumentDetails />,
        },
        {
          path: '/transactions',
          element: <Transactions />,
        },
        {
          path: '/transactions/:id',
          element: <TransactionDetails />,
        },
        {
          path: '/transactions-import/:id',
          element: <TransactionsImport />,
        },
        {
          path: '/csv-imports',
          element: <CSVImports />,
        },
        {
          path: '/csv-imports/:id',
          element: <CSVImports />,
        },
        {
          path: '/projects',
          element: <Projects />,
        },
        {
          path: '/contacts',
          element: <Contacts />,
        },
        {
          path: '/contacts/:id',
          element: <Contacts />,
        },
        {
          path: '/report',
          element: <Report />,
        },
        {
          path: '/taxes',
          element: <Taxes />,
        },
        {
          path: '/taxes/:id',
          element: <TaxesDetails />,
        },
        {
          path: '/categories',
          element: <Categories />,
        },
        {
          path: '/legal',
          element: <Legal />,
        },
        {
          path: '/income',
          element: <Income />,
        },
        {
          path: '/income/new/:id',
          element: <InvoiceGenerator />,
        },
        {
          path: '/income/:id',
          element: <Income />,
        },
        {
          path: '/expenses',
          element: <Expenses />,
        },
        {
          path: '/expenses/:id',
          element: <Expenses />,
        },
        {
          path: '/chat',
          element: <Tasks />,
        },
        {
          path: '/tasks/:id',
          element: <Tasks />,
        },
        {
          path: '/rules',
          element: <Rules />,
        },
        {
          path: '/payslips',
          element: <Payslips />,
        },
        {
          path: '/payslips/:id',
          element: <Payslips />,
        },
        {
          path: '/payroll',
          element: <Payroll />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutProtected greyBackground />,
      children: [
        {
          path: '/settings',
          element: <Settings />,
        },
        {
          path: '/settings/personal',
          element: <SettingsPersonal />,
        },
        {
          path: '/settings/company',
          element: <SettingsCompany />,
        },
        {
          path: '/settings/billing',
          element: <SettingsBilling />,
        },
        {
          path: '/settings/team-members',
          element: <SettingsTeamMembers />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutProtected noContentPadding />,
      children: [
        {
          path: '/transactions/:id',
          element: <TransactionDetails />,
        },
        {
          path: '/transactions-import/:id',
          element: <TransactionsImport />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutFullPage />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/forgot-password/message',
          element: <ForgotPasswordMessage />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/accept-invitation',
          element: <AcceptInvitation />,
        },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: '/confirm-email',
          element: <ConfirmEmail />,
        },
        isLogged
          ? {
            path: '/connect-bank',
            element: <ConnectBank />,
          }
          : {},
      ],
    },
    {
      path: '/',
      element: <LayoutPublic />,
      children: [
        {
          path: '/start',
          element: <Onboarding />,
        },
      ],
    },
    isLogged
      ? {
        path: '*',
        element: <LayoutProtected />,
        children: [
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      }
      : {
        path: '*',
        element: <LayoutFullPage />,
        children: [
          {
            path: '*',
            element: <Login />,
          },
        ],
      },
  ];

  appRoutes.forEach((route) => {
    route.children?.forEach((child) => {
      child.errorElement = <DefaultErrorComponent />;
    });
  });

  const removePaths: Record<string, string[]> = {
    transactions: ['transactions'],
    companyData: ['legal'],
    userManagement: ['team-members'],
    reports: ['report'],
    payroll: ['payroll'],
    incomesExpences: ['expences', 'invoicing'],
  };

  const routes = filteredRoutes(
    userAccess,
    appRoutes,
    removePaths,
    <AccessDenied />,
  );

  return [
    {
      path: '/',
      errorElement: <DefaultErrorComponent />,
      children: routes,
    },
  ];
};

const AppRouter = () => {
  const { isLogged, userAccess } = useAccount();

  const routes = useMemo(
    () => createRoutes(isLogged, userAccess),
    [isLogged, userAccess],
  );

  const router = createBrowserRouter(routes, {
    basename: import.meta.env.VITE_ROUTE_BASE_URL || '/',
  });

  return <RouterProvider router={router} fallbackElement={<Spin />} />;
};

export default AppRouter;
