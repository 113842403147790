//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$PaymentMethodController_findOne,
  ResponseContentType$PaymentMethodController_findOne,
  Response$PaymentMethodController_findOne$Status$200,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';
export const usePaymentMethodController_findOne = (options: {
  params: Params$PaymentMethodController_findOne['parameter'];
  config?: QueryOptions<
    Response$PaymentMethodController_findOne$Status$200[ResponseContentType$PaymentMethodController_findOne]
  >;
}) =>
  useApiClient<
    Params$PaymentMethodController_findOne,
    Response$PaymentMethodController_findOne$Status$200[ResponseContentType$PaymentMethodController_findOne]
  >({
    method: 'PaymentMethodController_findOne',
    params: { parameter: options.params },
    config: options.config,
  });
