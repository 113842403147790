//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$TransactionController_findOneById,
  ResponseContentType$TransactionController_findOneById,
  Response$TransactionController_findOneById$Status$200,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';
export const useTransactionController_findOneById = (options: {
  params: Params$TransactionController_findOneById['parameter'];
  config?: QueryOptions<
    Response$TransactionController_findOneById$Status$200[ResponseContentType$TransactionController_findOneById]
  >;
}) =>
  useApiClient<
    Params$TransactionController_findOneById,
    Response$TransactionController_findOneById$Status$200[ResponseContentType$TransactionController_findOneById]
  >({
    method: 'TransactionController_findOneById',
    params: { parameter: options.params },
    config: options.config,
  });
