import styled from '@emotion/styled';
import { Flex, type FlexProps } from 'antd';
import { rgba } from 'emotion-rgba';

export const Content = styled(Flex)<FlexProps & { width?: number }>`
  width: ${({ width }) => `${width || 327}px`};
  position: relative;
`;

export const Inner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isDisabled',
})<{
  isSelected: boolean;
  isDisabled?: boolean;
}>`
  width: 440px;
  padding: 0px;
  background: ${({ theme, isDisabled }) =>
    isDisabled ? rgba(theme.colors.black, 0.04) : theme.colors.white};
  transition: 0.3s;
  border-radius: 6px;
  padding: 0 11px;
  border: 1px solid
    ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue300 : theme.colors.strokeGrey};

  &:hover {
    border: 1px solid
      ${({ theme, isDisabled }) =>
    isDisabled ? theme.colors.strokeGrey : theme.colors.blue300};
  }

  .ui-input-affix-wrapper {
    background-color: transparent !important;
    padding-inline: 0px !important;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }

  .ui-input {
    border: none;
    padding-inline: 0px;
    background-color: transparent;
  }
`;

export const ScrollbarWrapper = styled.div`
  width: 440px;
  transition: 0.3s;
  box-shadow: 0 4px 21px 0 ${({ theme }) => rgba(theme.colors.dark, 0.14)};
  padding: 0px;
  position: absolute;
  z-index: 999999;
  top: 46px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;

  .contact-scrolbar {
    border-radius: 10px;
  }
`;

export const ButtonWrapper = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;
