import type { ThemeConfig } from 'antd';

import { colorPalette } from '..';

const isDevelopmentMode = import.meta.env.VITE_APP_MODE === 'DEV';

const developmentPalette = {
  base: '#610F5E',
  selected: '#3E0A3C',
  disabled: '#9F5F9D',
};

const layoutTokens: ThemeConfig['components'] = {
  Layout: {
    headerBg: isDevelopmentMode
      ? developmentPalette.base
      : colorPalette.blue500,
    siderBg: isDevelopmentMode ? developmentPalette.base : colorPalette.blue500,
    ...(isDevelopmentMode
      ? { siderActiveTab: developmentPalette.selected }
      : { siderActiveTab: colorPalette.black }),
    ...(isDevelopmentMode && {
      siderDisabledTabColor: developmentPalette.disabled,
    }),
    headerHeight: 72,
    headerPadding: '0 24px',
    headerColor: colorPalette.white,
  },
};

export default layoutTokens;
