import styled from '@emotion/styled';
import { Card as AntdCard, Flex, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

export const Card = styled(Flex)`
  width: 100%;
  height: calc(100vh - 300px);
  min-height: 544px;
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border: 1px solid ${({ theme }) => theme.colors.strokeDarkGrey};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  padding: 30px;

  .ui-tabs-nav {
    &:before {
      border-color: ${({ theme }) => theme.colors.textDisabled};
    }
  }
`;

export const Title = styled(Typography.Title)`
  && {
    --ui-typography-title-margin-top: 0;
    --ui-typography-title-margin-bottom: 0;
  }
`;

export const DetailsCard = styled(AntdCard)`
  max-width: 300px;
`;

export const CardHeader = styled(Flex)`
  min-height: 40px;
  width: 100%;
`;

export const Label = styled(Typography.Text)`
  color: ${({ theme }) => theme.colors.blue400};
  font-size: 12px;
  font-weight: 400;
`;

export const ProjectNameWrapper = styled(Flex)`
  max-width: calc(100% - 90px);
`;

export const ProjectName = styled(Typography.Text)`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
