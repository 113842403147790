import type { ThemeConfig } from 'antd';

import { colorPalette } from '..';

const tableTokens: ThemeConfig['components'] = {
  Table: {
    headerBg: colorPalette.white,
    headerColor: colorPalette.black,
    headerSplitColor: 'transparent',
    headerBorderRadius: 0,
    cellPaddingBlock: 8,
  },
};

export default tableTokens;
