import { Flex } from 'antd';
import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Button } from '@ui-kit/Button/Button';
import { colors } from '@theme';
import { IconEditUnderline } from '@assets';
import { InputBorderless } from '@components';

import * as S from './styled';

type InvoiceDocumentHeaderProps = {
  number: string;
  companyName?: string;
  onChange: (documentName: string, documentNumber: string) => void;
};

const InvoiceDocumentHeader: FC<InvoiceDocumentHeaderProps> = ({
  number,
  companyName,
  onChange,
}) => {
  const [isEditName, setIsEditName] = useState(false);
  const [documentNumber, setDocumentNumber] = useState(
    t('invoiceGenerator.document.number')(),
  );

  useEffect(() => {
    if (number) {
      setDocumentNumber(number);
    }
  }, [number]);

  const handleChange = useDebouncedCallback((value) => {
    setDocumentNumber(value);
    handleUpdateName(value);
  }, 1000);

  const handleUpdateName = (value: string) => {
    onChange(`${t('invoiceGenerator.document.name')()} ${value}`, value);

    setIsEditName(false);
  };

  const handleUpdateByKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleUpdateName(documentNumber);
    }
  };

  return (
    <S.Header align="flex-start" justify="space-between">
      <S.Title level={2}>
        <Flex gap={6} align="center">
          <Flex gap={6} align="center">
            {t('invoiceGenerator.document.name')()}

            {!isEditName ? (
              <span>{documentNumber}</span>
            ) : (
              <S.Name>
                <InputBorderless
                  defaultValue={documentNumber}
                  onKeyDown={handleUpdateByKeyPress}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </S.Name>
            )}
          </Flex>

          {!isEditName && (
            <Button
              type="link"
              icon={<IconEditUnderline color={colors.primary} />}
              onClick={() => setIsEditName(true)}
            />
          )}
        </Flex>
      </S.Title>

      <S.SubTitle level={5}>{companyName}</S.SubTitle>
    </S.Header>
  );
};

export default InvoiceDocumentHeader;
