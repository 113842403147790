//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import { Schemas } from '../types';
import { Params$CategoryGroupController_findAll } from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';
export const useCategoryGroupController_findAll = (options: {
  params: Params$CategoryGroupController_findAll['parameter'];
  config?: QueryOptions<{
    operational: {
      money_in?: Schemas.CategoryGroup[];
      money_out?: Schemas.CategoryGroup[];
    };
    financial: {
      money_in?: Schemas.CategoryGroup[];
      money_out?: Schemas.CategoryGroup[];
    };
    investments: {
      money_in?: Schemas.CategoryGroup[];
      money_out?: Schemas.CategoryGroup[];
    };
  }>;
}) =>
  useApiClient<
    Params$CategoryGroupController_findAll,
    {
      operational: {
        money_in?: Schemas.CategoryGroup[];
        money_out?: Schemas.CategoryGroup[];
      };
      financial: {
        money_in?: Schemas.CategoryGroup[];
        money_out?: Schemas.CategoryGroup[];
      };
      investments: {
        money_in?: Schemas.CategoryGroup[];
        money_out?: Schemas.CategoryGroup[];
      };
    }
  >({
    method: 'CategoryGroupController_findAll',
    params: { parameter: options.params },
    config: options.config,
  });
