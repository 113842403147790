import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Control = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.bgBrightBlue};

  .ui-input {
    padding: 0;
    resize: none;
    font-size: 13px;
  }
`;

export const ControlLabel = styled(Flex)`
  font-weight: 600;
  font-size: 13px;
`;
