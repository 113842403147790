//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$CategoriesController_delete,
  ResponseContentType$CategoriesController_delete,
  Response$CategoriesController_delete$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useCategoriesController_delete = () =>
  useApiClientMutation<
    Params$CategoriesController_delete,
    Response$CategoriesController_delete$Status$200[ResponseContentType$CategoriesController_delete]
  >({ method: 'CategoriesController_delete' });
