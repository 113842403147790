import styled from '@emotion/styled';
import {Typography} from 'antd';

export const TwoColumns = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 340px 1fr;
  width: 100%;
  gap: 32px;
`;

export const Title = styled(Typography.Title)`
  && {
    --ui-typography-title-margin-top: 0;
    --ui-typography-title-margin-bottom: 0;
  }
`
