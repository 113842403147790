import { ColumnDef, SortingState, Updater } from '@tanstack/react-table';
import { Button, message, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { colors } from '@theme';
import { IconMissedFile } from '@assets';
import { Amount, DateText, TransactionContentModal } from '@entities';
import { ContactCell } from '@entities/transactions/TransactionsTable/ContactCell';
import {
  QueryParams,
  queryParamsSchema,
  toURLSearchParams,
} from '@entities/transactions/TransactionsTable/schema';
import InfiniteTable, { useScrollContext } from '@components/InfiniteTable';
import { InfiniteTableRef } from '@components/InfiniteTable/types';
import { useCustomInfiniteQuery } from '@components/InfiniteTable/useCustomInfiniteQuery';
import { useAccount } from '@hooks';
import { getDefaultValues, removeDefaultOrEmptyValues } from '@utils/zod';
import { Schemas } from '@api-client/generated/types';
import { DEFAULT_CURRENCY_CODE } from '@constants';

import * as S from './styled';
import { useTransactionController_updateOneById } from '@api-client/generated/TransactionController/updateOneById';
import ProjectsCell from '@entities/transactions/TransactionsTable/ProjectCell';
import { transactionControllerFindAll } from '../../../api/generated/api.gen';

type Transaction = Schemas.Transaction;

const PER_PAGE = 25;
const CELL_HEIGHT = 55;

type TransactionsInfiniteTableProps = {
  scrollTop?: number;
  onScroll?: (event: React.UIEvent<HTMLElement>) => void;
  queryParams?: Partial<QueryParams>;
  setQueryParams?: (value: Partial<QueryParams>) => void;
  filterForm?: (props: {
    onFormUpdate: (values: Partial<QueryParams>) => void;
    total: number;
  }) => ReactNode;
};

const TransactionsInfiniteTable = (props: TransactionsInfiniteTableProps) => {
  const { companyId, userAccess } = useAccount();
  const { getPage } = useScrollContext();
  const infiniteTableRef = useRef<InfiniteTableRef>(null);

  const { mutateAsync: updateTransacton } =
    useTransactionController_updateOneById();

  const [formData, setFormData] = useState<QueryParams>(() => ({
    ...getDefaultValues(queryParamsSchema),
    ...props.queryParams,
  }));

  const [modalData, setModalData] = useState<
    (Schemas.Transaction & { page: number }) | null
  >(null);
  const [detailsPage, setDetailsPage] = useState(1);

  const [sorting, setSorting] = useState<SortingState>(() => {
    if (formData.sortBy && formData.sortDirection) {
      return [
        {
          id: formData.sortBy,
          desc: formData.sortDirection === 'DESC',
        },
      ];
    }

    return [];
  });

  const initialPage = getPage(CELL_HEIGHT, PER_PAGE);

  const [page, setPage] = useState(initialPage || 1);
  const [visiblePages, setVisiblePages] = useState<number[]>([]);

  const sortingParams = sorting[0]
    ? {
        sortBy: sorting[0].id as QueryParams['sortBy'],
        sortDirection: sorting[0].desc ? ('DESC' as const) : ('ASC' as const),
      }
    : {
        sortBy: undefined,
        sortDirection: undefined,
      };

  const filter = toURLSearchParams({
    ...removeDefaultOrEmptyValues(queryParamsSchema, formData),
    ...sortingParams,
  } as QueryParams);

  const navigate = useNavigate();

  const {
    data: rawData,
    loadingPages,
    reset,
    totalItems,
    isLoading,
    isFetching,
    hasNextPage,
    hasPrevPage,
    refetchPages,
  } = useCustomInfiniteQuery<Schemas.PaginatedTrasactionResponse>({
    queryKey: ['transactions', filter],
    queryFn: async ({ page }) =>
      transactionControllerFindAll({
        ...filter,
        page,
      }),
    page,
    visiblePages,
    getHasNextPage: (data) => data.metadata.totalPages > page,
    getHasPrevPage: () => page > 1,
    getTotalItems: (data) => data.metadata.totalRecords,
    getCurrentPage: (data) => data.metadata.currentPage,
  });

  const columns = useMemo<ColumnDef<Transaction & { page: number }>[]>(
    () => [
      ...(userAccess?.incomesExpences
        ? ([
            {
              accessorKey: 'file',
              header: '',
              size: 90,
              enableSorting: false,
              cell: (info) => {
                const row = info.row.original;
                return (
                  !row.documents?.length &&
                  !row.isFileless && (
                    <Tooltip
                      title={t('transactionsPage.missedPaymentDocument')()}
                      placement="right"
                    >
                      <Button
                        icon={<IconMissedFile color={colors.error} />}
                        type="text"
                        size="small"
                      />
                    </Tooltip>
                  )
                );
              },
            },
          ] as ColumnDef<Transaction & { page: number }>[])
        : []),
      {
        accessorKey: 'amount',
        size: 130,
        header: () => t('transactionsPage.label.amount')(),
        cell: (info) => (
          <S.AmountCell>
            <Amount
              amount={info.getValue() as number}
              currencyCode={DEFAULT_CURRENCY_CODE} // TODO: use currency of a record
              withColors
            />
          </S.AmountCell>
        ),
      },
      {
        accessorKey: 'contact',
        header: t('transactionsPage.label.contact')(),
        size: 180,
        cell: (info) => {
          const data = info.row.original;

          return (
            <ContactCell
              contact={data.contact}
              rawContactName={data.rawContactName}
              assignContact={(contact) => {
                updateTransacton(
                  {
                    parameter: { companyId: companyId!, id: data.id },
                    requestBody: { contact },
                  },
                  {
                    onSuccess: () => {
                      refetchPages(visiblePages);
                      message.success(
                        t('transactionsPage.contactWasChanged')(),
                      );
                    },
                  },
                );
              }}
              onRulesSubmit={() => {
                refetchPages(visiblePages);
              }}
            />
          );
        },
      },
      {
        accessorKey: 'details',
        header: t('transactionsPage.label.details')(),
        enableSorting: false,
        size: 0,
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'category',
        header: t('transactionsPage.label.category')(),
        size: 150,
        cell: (info) => {
          const data = info.row.original;
          return data.category?.name || '-';
        },
      },
      {
        accessorKey: 'project',
        header: t('transactionsPage.label.project')(),
        width: 150,
        enableSorting: false,
        cell: (info) => {
          const value = info.getValue() as Schemas.Contact | null;
          const record = info.row.original;

          return (
            <ProjectsCell
              project={value}
              onProjectAssign={(projectId) => {
                updateTransacton(
                  {
                    parameter: { companyId: companyId!, id: record.id },
                    requestBody: { project: { id: projectId } },
                  },
                  {
                    onSuccess: () => {
                      refetchPages(visiblePages);
                    },
                  },
                );
              }}
            />
          );
        },
      },
      {
        accessorKey: 'account',
        header: t('transactionsPage.label.accountName')(),
        enableSorting: false,
        size: 150,
        cell: (info) => {
          const account = info.getValue() as Schemas.Account;

          return account?.connection?.bank?.name || account?.accountName;
        },
      },
      {
        accessorKey: 'date',
        header: t('transactionsPage.label.date')(),
        size: 110,
        cell: (info) => {
          const date = new Date(info.row.original.bookingDate);

          return <DateText date={date} format="date" />;
        },
      },
      {
        accessorKey: 'plDate',
        header: t('transactionsPage.label.plDate')(),
        size: 110,
        cell: (info) => {
          const data = info.row.original;

          return data.isIgnoredForPL ? (
            <S.ExcludedTransaction>
              {t('transactionsPage.excluded')()}
            </S.ExcludedTransaction>
          ) : (
            info.getValue() && dayjs(info.getValue() as Date).format('MMM YYYY')
          );
        },
      },
    ],
    // eslint-disable-next-line
    [userAccess, visiblePages, refetchPages],
  );

  const data = useMemo<(Transaction & { page: number })[]>(
    () =>
      Object.values(rawData?.pages || {})
        .map((page) =>
          page.records.map((record) => ({
            page: page.metadata.currentPage,
            ...record,
          })),
        )
        .flat(),
    [rawData],
  );

  useEffect(() => {
    props.setQueryParams?.({
      ...props.queryParams,
      ...sortingParams,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const handleFormUpdate = (values: Partial<QueryParams>) => {
    setFormData(
      (formData) =>
        ({
          ...formData,
          ...values,
        }) as QueryParams,
    );

    props.setQueryParams?.({
      ...props.queryParams,
      ...values,
    });
    setPage(1);
    reset();
    infiniteTableRef.current?.resetScroll();
  };

  const handleSortChange = (updater: Updater<SortingState>) => {
    setSorting(updater);
    setPage(1);
    reset();
    infiniteTableRef.current?.resetScroll();
  };

  const handleChangeSettings = useCallback(() => {
    refetchPages(visiblePages);
  }, [refetchPages, visiblePages]);

  return (
    <S.Root gap={24} flex={1} vertical>
      {props.filterForm?.({
        onFormUpdate: handleFormUpdate,
        total: totalItems,
      })}
      <InfiniteTable<Transaction & { page: number }>
        ref={infiniteTableRef}
        isLoading={isLoading}
        isFetching={isFetching}
        columns={columns}
        sorting={sorting}
        onSortChange={handleSortChange}
        data={data || []}
        overscan={2}
        cellHeight={CELL_HEIGHT}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        totalItems={totalItems}
        visiblePages={visiblePages}
        perPage={PER_PAGE}
        loadingPages={loadingPages}
        onVisiblePagesChange={setVisiblePages}
        onRowClick={(event, row, index) => {
          if (event.ctrlKey || event.metaKey) {
            navigate(`/transactions/${row.id}`);
          } else {
            setModalData(row);
            setDetailsPage(index);
          }
        }}
        {...props}
      />

      {modalData && (
        <TransactionContentModal
          id={modalData.id}
          open={!!modalData}
          page={detailsPage}
          onCancel={() => setModalData(null)}
          filterOptions={{ ...props.queryParams }}
          handleChangeSettings={handleChangeSettings}
          onUpdateTransaction={() => {
            refetchPages(visiblePages);
          }}
        />
      )}
    </S.Root>
  );
};

export default TransactionsInfiniteTable;
