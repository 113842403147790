import styled from '@emotion/styled';
import { Flex, type FlexProps } from 'antd';

import { type PaymentDocumentInvoiceProps } from './PaymentDocumentCard';

export const Card = styled(Flex, {
  shouldForwardProp: (prop) =>
    prop !== 'isSelected' && prop !== 'isToReview' && prop !== 'isDraft',
})<FlexProps & Omit<PaymentDocumentInvoiceProps, 'onClick'>>`
  min-height: 80px;
  border-radius: 10px;
  background-color: ${({ theme, isSelected, isDraft, isToReview }) => {
    if (isDraft) {
      return isSelected ? theme.colors.bgDarkGrey : theme.colors.bgGrey;
    }

    if (isToReview) {
      return theme.colors.yellow200;
    }

    return isSelected ? theme.colors.bgDarkGrey : theme.colors.white;
  }};
  cursor: pointer;
  padding: 16px;
  border: 1px solid
    ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.strokeDarkGrey};
  box-shadow: 0 0 0 1px
    ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : 'transparent'}
    inset;
  clip-path: polygon(0 0, calc(100% - 20px) 0, 100% 20px, 100% 100%, 0 100%);
  position: relative;

  &:after {
    content: '';
    width: 28px;
    height: ${({ isSelected }) => `${isSelected ? 2 : 1}px`};
    background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary : theme.colors.strokeDarkGrey};
    position: absolute;
    top: 9px;
    right: -5px;
    z-index: 2;
    transform: rotate(45deg);
  }
`;
