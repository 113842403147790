import { Schemas } from '@api-client/generated/types';
import { STORAGE_COMPANY_KEY } from '@constants';

export const getUserAccess = (account: Schemas.User): Schemas.AccessRights => {
  const storageSelectedCompany = JSON.parse(
    localStorage.getItem(STORAGE_COMPANY_KEY)!
  );

  if (storageSelectedCompany) {
    const storageSelectedCompanyFromUser = account.companies.find(
      (company) => company.id === storageSelectedCompany.id
    );

    return (
      storageSelectedCompanyFromUser?.userAccessRights ||
      account.companies[0]?.userAccessRights
    );
  } else {
    return account.companies[0]?.userAccessRights;
  }
};
