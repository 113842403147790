import type { ThemeConfig } from 'antd';

const headingSizes = {
  h1: 48,
  h2: 36,
  h3: 28,
  h4: 24,
  h5: 20,
};

const typographyTokens: ThemeConfig['components'] = {
  Typography: {
    fontWeightStrong: 600,

    fontSizeHeading1: headingSizes.h1,
    fontSizeHeading2: headingSizes.h2,
    fontSizeHeading3: headingSizes.h3,
    fontSizeHeading4: headingSizes.h4,
    fontSizeHeading5: headingSizes.h5,

    lineHeightHeading1: 58 / headingSizes.h1,
    lineHeightHeading2: 44 / headingSizes.h2,
    lineHeightHeading3: 36 / headingSizes.h3,
    lineHeightHeading4: 28 / headingSizes.h4,
    lineHeightHeading5: 28 / headingSizes.h5,

    titleMarginBottom: 16,
    titleMarginTop: 0,
  },
};

export default typographyTokens;
