import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useBlocker, useLocation } from 'react-router-dom';

import { ScrollRestorationContext } from '@components/InfiniteTable/context';

type ScrollRestorationProps = {
  name: string;
  children: ReactNode;
};

/**
 * Helps to restore scroll position when navigating between pages.
 * @usage
 * Just wrap your table wrapper with this component and don't forget to pass
 * two props: `scrollTop` and `onScroll` to InfiniteTable component.
 *
 * @param props.name unique name for scroll restoration
 */
export const ScrollRestoration = (props: ScrollRestorationProps) => {
  const scrollPosition = JSON.parse(sessionStorage.getItem(`scrollPosition:${props.name}`) || '{}');
  const search = window.location.search;

  const scrollRef = useRef<number>(
    scrollPosition[search] || 0,
  );

  useBeforeNavigate(
    useCallback(() => {
      const scrollPosition = {
        [search]: scrollRef.current,
      };

      sessionStorage.setItem(
        `scrollPosition:${props.name}`,
        JSON.stringify(scrollPosition),
      );
    }, [props.name, search]),
  );

  const handleScroll = (event: React.UIEvent<HTMLElement>) => {
    scrollRef.current = event.currentTarget.scrollTop;
  };

  const value = useMemo(
    () => ({
      getPage: (cellHeight: number, perPage: number) =>
        Math.ceil(scrollRef.current / cellHeight / perPage) || 1,
    }),
    [],
  );

  return (
    <ScrollRestorationContext.Provider value={value}>
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            ...child.props,
            onScroll: (event: React.UIEvent<HTMLElement>) => {
              if (child.props.onScroll) {
                child.props.onScroll(event);
              }
              handleScroll(event);
            },
            scrollTop: scrollRef.current,
          });
        }
        return child;
      })}
    </ScrollRestorationContext.Provider>
  );
};

/**
 * This hook is used to call callback before navigating to another page.
 * It uses useBlocker hook from react-router-dom to block navigation.
 *
 * We check if current location is equal to next location and if it is not
 * then we block the navigation. We also check if current location is equal
 * the location hook was called from. We need this because without this
 * check we will block navigation for the whole application.
 *
 * @param callback - function that will be called before navigation
 */
const useBeforeNavigate = (callback: () => void) => {
  const location = useLocation();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      currentLocation.pathname === location.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      callback?.();
      blocker.proceed?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker]);
};
