import type { ThemeConfig } from 'antd';

import cardTokens from './card';
import drawerTokens from './drawer';
import inputTokens from './input';
import layoutTokens from './layout';
import switchTokens from './switch';
import tableTokens from './table';
import tooltipTokens from './tooltip';
import typographyTokens from './typography';

export const theme: ThemeConfig = {
  cssVar: true,
  token: {
    fontFamily: 'Inter',
  },
  components: {
    ...cardTokens,
    ...drawerTokens,
    ...layoutTokens,
    ...switchTokens,
    ...tableTokens,
    ...tooltipTokens,
    ...typographyTokens,
    ...inputTokens,
  },
};
