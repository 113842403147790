import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Form, Typography } from 'antd';
import { rgba } from 'emotion-rgba';

import { Loader as CustomLoader } from '@components';

export const TableContainer = styled.div`
  .ui-table-row {
    cursor: pointer;
  }
`;

export const Loader = styled(CustomLoader)`
  margin-top: 150px;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;

export const FormControl = styled(Form.Item)`
  && {
    margin-bottom: 0;
  }
`;

export const DrawerContainer = styled.div`
  height: calc(100vh - 112px);
`;

export const DrawerTitle = styled(Typography.Title)`
  &.ui-typography {
    font-size: 28px;
    margin-bottom: 0;
  }
`;

export const SwitchLabel = styled.div`
  font-weight: 600;
`;

export const Divider = styled.div`
  width: 1px;
  height: 40px;
  background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  margin: 0 8px;
`;

export const HoverableButton = styled('div')();

export const Invisible = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

export const CellWithButton = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDropdownOpen', 'isContactSelectorOpen', 'hasInfo'].includes(prop),
})<{
  isDropdownOpen: boolean;
  isContactSelectorOpen: boolean;
  hasInfo: boolean;
}>`
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding: 16px;
  margin: 0 -16px;
  position: relative;
  display: flex;

  background-color: ${({ isDropdownOpen, theme }) =>
    isDropdownOpen && theme.colors.grey100};
  background-color: ${({ hasInfo, theme }) =>
    !hasInfo && theme.colors.yellow200};
  color: ${({ hasInfo, theme }) => !hasInfo && theme.colors.grey500};

  ${({ isContactSelectorOpen, theme }) =>
    isContactSelectorOpen &&
    css`
      border-radius: 6px;
      outline: 2px solid ${theme.colors.grey300};
      outline-offset: -2px;
      background: ${theme.colors.grey100};
      box-shadow: 0px 3px 8px 0px ${rgba(theme.colors.dark, 0.1)};
    `}

  ${HoverableButton} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.grey100};
    visibility: ${({ isDropdownOpen }) => isDropdownOpen ? 'visible' : 'hidden'};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};

    ${HoverableButton} {
      background-color: ${({ theme }) => theme.colors.grey100};
      visibility: ${({ isContactSelectorOpen }) => isContactSelectorOpen ? 'hidden' : 'visible'};
    }
  }
`;