import { Flex, Typography } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';

import { Loader } from '@components';

type PageMeta = {
  title: string;
};

type PageWrapperProps = {
  meta: PageMeta;
  title?: string;
  isLoading?: boolean;
  isEmpty?: boolean;
  emptyComponent?: ReactNode;
};

const { Title } = Typography;

const PageWrapper = ({
  meta,
  title,
  isLoading,
  isEmpty,
  emptyComponent,
  children,
}: PropsWithChildren<PageWrapperProps>) => (
  <>
    <Helmet>
      <title>EasyBiz | {meta.title}</title>
    </Helmet>

    {isLoading ? (
      <Loader />
    ) : isEmpty && emptyComponent ? (
      emptyComponent
    ) : (
      <Flex gap={16} vertical>
        {title && <Title level={2}>{title}</Title>}

        {children}
      </Flex>
    )}
  </>
);

export default PageWrapper;
