import axios, {AxiosRequestConfig, HttpStatusCode, InternalAxiosRequestConfig} from "axios";
import queryString from "query-string";

import { ROUTES_ADDITIONAL_PUBLIC, STORAGE_COMPANY_KEY, STORAGE_TOKEN_KEY } from "@constants";

import { Company } from "./generated";

export type ClientOptions = AxiosRequestConfig;

export const client = axios.create({
  baseURL: import.meta.env.VITE_ROUTE_API_BASE_URL || '',
  paramsSerializer: (params) => queryString.stringify(params, {arrayFormat: 'comma'}),
});

client.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const tokenJWT = localStorage.getItem(STORAGE_TOKEN_KEY);
    if (tokenJWT) config.headers['Authorization']= `Bearer ${tokenJWT}`;

    const company = localStorage.getItem(STORAGE_COMPANY_KEY);
    if (company) config.headers['CompanyId'] = (JSON.parse(company) as Company).id;

    return config;
  },
);

axios.interceptors.response.use(
  async (response) => response,
  async (error) => {
    if (
      error.response.status === HttpStatusCode.Unauthorized &&
      !ROUTES_ADDITIONAL_PUBLIC.includes(location.pathname)
    ) {
      localStorage.removeItem(STORAGE_TOKEN_KEY);
      localStorage.removeItem(STORAGE_COMPANY_KEY);

      location.replace('/login');
    }

    return error;
  },
);