import { Api } from '@api';
import { useQuery } from '@tanstack/react-query';

const QUERY_KEYS = {
  BILLING_DOCUMENTS: ['billing-documents', 'list'] as const,
};

export const useBillingDocumentList = () =>
  useQuery({
    queryKey: QUERY_KEYS.BILLING_DOCUMENTS,
    queryFn: Api.billingDocumentControllerFindAll,
    staleTime: 20 * 1000,
  });
