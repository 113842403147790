import { useCallback } from 'react';

type InfiniteScrollParams = {
  /**
   * Height of each item in pixels
   */
  height: number;
  /**
   * Amount of items loaded per page
   */
  perPage: number;
};

export const useInfiniteScroll = (params: InfiniteScrollParams) => {
  const getItemPosition = (page: number, index: number) =>
    (page - 1) * params.perPage * params.height + index * params.height;

  const getStyle = useCallback(
    (page: number, index: number) => ({
      top: getItemPosition(page, index),
      height: params.height,
      position: 'absolute' as const,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.height],
  );

  const getTableHeight = useCallback(
    (itemsCount: number) => params.height * itemsCount,
    [params.height],
  );

  return {
    getItemPosition,
    getStyle,
    getTableHeight,
  };
};
