import { type InputProps } from 'antd';
import { FC } from 'react';

import { colors } from '@theme';
import { IconSearch } from '@assets';

import * as S from './styled';

export type SearchControlProps = {
  width?: number;
  fitWidth?: boolean;
  showPrefix?: boolean;
  testid?: string;
} & InputProps;

const SearchControl: FC<SearchControlProps> = ({
  width,
  fitWidth,
  showPrefix = true,
  testid,
  ...rest
}) => (
  <S.Control
    size="large"
    placeholder="Search"
    prefix={showPrefix && <IconSearch color={colors.text200} />}
    variant="filled"
    data-testid={testid || 'search-control'}
    width={width}
    fitWidth={fitWidth}
    allowClear
    {...rest}
  />
);

export default SearchControl;
