//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$DocumentController_delete,
  ResponseContentType$DocumentController_delete,
  Response$DocumentController_delete$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useDocumentController_delete = () =>
  useApiClientMutation<
    Params$DocumentController_delete,
    Response$DocumentController_delete$Status$200[ResponseContentType$DocumentController_delete]
  >({ method: 'DocumentController_delete' });
