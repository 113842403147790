import _ from 'lodash'
import { useCallback, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { z } from 'zod'

import { ObjectWithOtherKeys, parseSearchParams, stringifySearchParams } from '@utils'
import { removeDefaultOrEmptyValues } from '@utils/zod'

type UseQueryParamsConfig<T extends z.AnyZodObject> = {
  schema: T
  defaultValues: z.infer<T>
}

/**
 * Hook that allows you to work with query parameters with schema validation.
 * 
 * Supposed to be used on the top level component (probably a page).
 */
export function useQueryParams<T extends z.AnyZodObject>(
  config: UseQueryParamsConfig<T>,
) {
  function getMergedQueryParams() {
    const fromQuery = parseSearchParams<T>(window.location.search, config.schema);
    return {...config.defaultValues, ...fromQuery};
  }

  function validateOrDefault(merged: ObjectWithOtherKeys<T>) {
    const parseResult = config.schema.safeParse(merged);

    if (parseResult.success) {
      return removeDefaultOrEmptyValues(config.schema, parseResult.data);
    } else {
      console.error(parseResult.error);
      return config.defaultValues;
    }
  }

  const [queryParams, setQueryParamsState] = useState<typeof config.defaultValues>(validateOrDefault(getMergedQueryParams()))

  const setQueryParams = useCallback((newParams: Partial<z.infer<T>>): void => {
    const parseResult = config.schema.safeParse(newParams);
    if (!parseResult.success) {
      console.error(
        'Error writing to query params! Please, check the provided schema',
      );
      console.error(parseResult.error);
    }

    const nonDefaultOrEmpty = removeDefaultOrEmptyValues(config.schema, newParams);
    setQueryParamsState(nonDefaultOrEmpty);

    updateUrlParams(nonDefaultOrEmpty);
  }, [config]); // eslint-disable-line

  const updateUrlParams = useDebouncedCallback((params: typeof config.defaultValues) => {
    if (Object.keys(params).length > 0) {
      const stringParams = stringifySearchParams(params)
      window.history.replaceState(null, '', `${window.location.pathname}?${stringParams}`)
    } else {
      window.history.replaceState(null, '', `${window.location.pathname}`)
    }
  }, 300)

  const setQueryParam = useCallback(<K extends keyof typeof config.defaultValues>(param: K, value: typeof config.defaultValues[K]) => {
    setQueryParams({...queryParams, [param]: value});
  }, [config]); // eslint-disable-line

  const resetQueryParams = useCallback(() => {
    setQueryParams(config.defaultValues);
  }, []) // eslint-disable-line

  return {
    /** query params according to passed schema */
    queryParams,
    /** set query params according to passed schema */
    setQueryParams,
    /** set one of the query params according to passed schema */
    setQueryParam,
    /** resets to default state according to passed schema */
    resetQueryParams,
  }
}