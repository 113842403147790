import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { LoginForm, PageMeta, PublicCardForm } from '@entities';
import { useAccount } from '@hooks';
import { useAuthLogIn } from '@hooks-api';
import { getUserAccess } from '@utils';

const Login: FC = () => {
  const navigate = useNavigate();

  const { updateToken, setAccount, company } = useAccount();

  const { mutate: logIn, isPending: loading } = useAuthLogIn();

  const [error, setError] = useState('');

  const handleSubmit = (values: Schemas.LoginDto) => {
    logIn(
      { requestBody: values },
      {
        onSuccess: (response) => {
          const userAccess = getUserAccess(response.user);

          updateToken(response.accessToken);
          setAccount(response.user);

          if (company?.status === 'incorporating') {
            navigate('/start');
          } else {
            navigate(userAccess?.transactions ? '/transactions' : '/documents');
          }
        },
        onError: (error) =>
          setError((error.response?.data?.message || [])[0] || ''),
      }
    );
  };

  return (
    <>
      <PageMeta title={t('auth.login.title')()} />

      <PublicCardForm
        title={t('auth.login.title')()}
        subTitle={t('auth.login.subtitle')()}
      >
        <LoginForm
          onSubmit={handleSubmit}
          onError={setError}
          error={error}
          isLoading={loading}
        />
      </PublicCardForm>
    </>
  );
};

export default Login;
