import { Col, Flex, message, Row, Table } from 'antd';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { IconPlus } from '@assets';
import { ButtonDashed } from '@components';
import { useAccount } from '@hooks';
import { useCompanyController_updateOneById } from '@api-client/generated/CompanyController/updateOneById';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_TIMEOUT_FOR_DEBOUNCE,
} from '@constants';

import InvoiceModalBankAction from '../../modals/InvoiceModalBankAction';
import InvoiceModalContactAction from '../../modals/InvoiceModalContactAction';
import InvoiceModalFromDetails from '../../modals/InvoiceModalFromDetails';
import InvoiceModalItemAction from '../../modals/InvoiceModalItemAction';
import InvoiceDocumentDateEditor from '../InvoiceDocumentDateEditor';
import InvoiceDocumentDetailsFrom from '../InvoiceDocumentDetailsFrom';
import InvoiceDocumentDetailsTo from '../InvoiceDocumentDetailsTo';
import InvoiceDocumentFieldEditor from '../InvoiceDocumentFieldEditor';
import InvoiceDocumentFooter from '../InvoiceDocumentFooter';
import InvoiceDocumentHeader from '../InvoiceDocumentHeader';
import InvoiceDocumentTotal from '../InvoiceDocumentTotal';
import * as helpers from './helpers';
import * as S from './styled';

type InvoiceItem = Schemas.InvoiceItem;

type InvoiceDocumentCardProps = {
  document: Schemas.Document;
  company: Schemas.Company;
  onRefetchCompany: VoidFunction;
  onAfterUpdate: VoidFunction;
};

type UpdateDocumentDto = Omit<Schemas.UpdateDocumentDto, 'documentMetadata'> & {
  documentMetadata?: Partial<Schemas.DocumentMetadata>;
};

type UnitModalMode = 'add' | 'edit';

type ModalAction = {
  isOpen: boolean;
  mode: UnitModalMode;
};

const InvoiceDocumentCard: FC<InvoiceDocumentCardProps> = ({
  document,
  company,
  onRefetchCompany,
  onAfterUpdate,
}) => {
  const { companyId } = useAccount();

  const [isEditDataVisible, setIsEditDataVisible] = useState(false);

  const [modalBankAdd, setModalBankAdd] = useState<ModalAction>({
    isOpen: false,
    mode: 'add',
  });

  const [modalContactAdd, setModalContactAdd] = useState<ModalAction>({
    isOpen: false,
    mode: 'add',
  });

  const [isItemActionVisible, setIsItemActionVisible] = useState(false);
  const [actionItemType, setActionItemType] = useState<UnitModalMode>('add');
  const [selectedItem, setSelectedItem] = useState<InvoiceItem | null>(null);

  const { mutate: updateDocument } = useDocumentController_updateOneById();
  const { mutate: updateCompany } = useCompanyController_updateOneById();

  const documentItems = document.documentMetadata?.items || [];

  const columns = helpers.getTableColumns(document.documentMetadata?.currency);

  const handleUpdateDocumentWithDebounce = useDebouncedCallback((values) => {
    handleUpdateDocument(values);
  }, DEFAULT_TIMEOUT_FOR_DEBOUNCE);

  const handleCancelActionItem = useCallback(() => {
    setIsItemActionVisible(false);
    setActionItemType('add');
    setSelectedItem(null);
  }, []);

  const handleUpdateDocument = useCallback(
    (values: UpdateDocumentDto, withSuccessMessage = true) => {
      updateDocument(
        {
          parameter: {
            companyId: companyId!,
            id: document.id!,
          },
          requestBody: values as Schemas.UpdateDocumentDto,
        },
        {
          onSuccess: () => {
            onAfterUpdate();

            if (isItemActionVisible) {
              handleCancelActionItem();
            }

            if (withSuccessMessage) {
              message.success(
                t('invoiceGenerator.document.invoiceUpdatedMessage')(),
              );
            }
          },
        },
      );
    },
    [
      updateDocument,
      companyId,
      document.id,
      onAfterUpdate,
      isItemActionVisible,
      handleCancelActionItem,
    ],
  );

  useEffect(() => {
    if (!document.documentMetadata) {
      handleUpdateDocument(
        {
          documentMetadata: {
            currency: DEFAULT_CURRENCY_CODE,
            issueDate: dayjs(new Date()).toISOString(),
            isCreatedByUser: true,
          },
        },
        false,
      );
    }
  }, [document.documentMetadata, handleUpdateDocument]);

  const handleAddItem = () => {
    setIsItemActionVisible(true);
    setActionItemType('add');
  };

  const handleSubmitItem = (values: InvoiceItem) => {
    const mergedItems = [...documentItems, values];
    const updatedItems = documentItems.map((item) =>
      item.id === (selectedItem && selectedItem.id)
        ? { ...item, ...values }
        : item,
    );
    const itemsByType = actionItemType === 'add' ? mergedItems : updatedItems;

    handleUpdateDocument({
      documentMetadata: {
        ...document.documentMetadata,
        amount: helpers.getTotalAmount(itemsByType),
        items: itemsByType,
      },
    });
  };

  const handleRemoveItem = (id: string) => {
    const filteredDocumentItems = documentItems.filter(
      (item) => item.id !== id,
    );

    handleUpdateDocument({
      documentMetadata: {
        ...document.documentMetadata,
        amount: helpers.getTotalAmount(filteredDocumentItems),
        items: filteredDocumentItems,
      },
    });
  };

  const handleOnRow = (record: InvoiceItem) => ({
    onClick: () => {
      setIsItemActionVisible(true);
      setActionItemType('edit');
      setSelectedItem(record);
    },
  });

  const handleUpdateCompany = (values: Schemas.CompanyDto) => {
    updateCompany(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: values,
      },
      {
        onSuccess: () => {
          setIsEditDataVisible(false);

          setModalBankAdd({
            isOpen: false,
            mode: 'add',
          });

          setModalContactAdd({
            isOpen: false,
            mode: 'add',
          });

          onRefetchCompany();

          message.success(
            t('invoiceGenerator.document.invoiceUpdatedMessage')(),
          );
        },
      },
    );
  };

  return (
    <S.Card justify="space-between" vertical>
      <InvoiceModalFromDetails
        open={isEditDataVisible}
        name={company.name}
        details={company.details}
        onSubmit={handleUpdateCompany}
        onCancel={() => setIsEditDataVisible(false)}
      />

      <InvoiceModalBankAction
        mode={modalBankAdd.mode}
        details={company.details}
        open={modalBankAdd.isOpen}
        onSubmit={handleUpdateCompany}
        onCancel={() =>
          setModalBankAdd({
            isOpen: false,
            mode: 'add',
          })
        }
      />

      <InvoiceModalContactAction
        mode={modalContactAdd.mode}
        details={company.details}
        open={modalContactAdd.isOpen}
        onSubmit={handleUpdateCompany}
        onCancel={() =>
          setModalContactAdd({
            isOpen: false,
            mode: 'add',
          })
        }
      />

      {isItemActionVisible && (
        <InvoiceModalItemAction
          type={actionItemType}
          item={selectedItem}
          currency={document.documentMetadata?.currency}
          open={isItemActionVisible}
          onSubmit={handleSubmitItem}
          onCancel={handleCancelActionItem}
          onRemove={handleRemoveItem}
        />
      )}

      <Flex vertical>
        <InvoiceDocumentHeader
          number={document.documentMetadata?.number || ''}
          companyName={company?.name}
          onChange={(documentName, documentNumber) =>
            handleUpdateDocument({
              name: documentName,
              documentMetadata: {
                ...document.documentMetadata,
                number: documentNumber,
              },
            })
          }
        />

        <Flex gap={48} vertical>
          <Flex gap={5} vertical>
            <InvoiceDocumentDateEditor
              label={t('invoiceGenerator.document.issuedOn')()}
              date={document.documentMetadata?.issueDate}
              onChange={(date) =>
                handleUpdateDocument({
                  documentMetadata: {
                    ...document.documentMetadata,
                    issueDate: date,
                  },
                })
              }
            />

            <InvoiceDocumentDateEditor
              label={t('invoiceGenerator.document.dueDate')()}
              date={document.documentMetadata?.dueDate}
              onChange={(date) =>
                handleUpdateDocument({
                  documentMetadata: {
                    ...document.documentMetadata,
                    dueDate: date,
                  },
                })
              }
            />

            <InvoiceDocumentDateEditor
              label={t('invoiceGenerator.document.deliveryDate')()}
              date={document.documentMetadata?.deliveryDate}
              onChange={(date) =>
                handleUpdateDocument({
                  documentMetadata: {
                    ...document.documentMetadata,
                    deliveryDate: date,
                  },
                })
              }
            />
          </Flex>

          <Row gutter={[32, 0]}>
            <Col span={12}>
              <InvoiceDocumentDetailsTo
                contact={document.contact}
                onSelect={(contact) =>
                  handleUpdateDocument({
                    contactId: contact.id,
                  })
                }
              />
            </Col>

            <Col span={12}>
              <InvoiceDocumentDetailsFrom
                name={company.name}
                details={company.details}
                onAction={() => setIsEditDataVisible(true)}
              />
            </Col>
          </Row>
        </Flex>

        <S.Table gap={8} vertical>
          <Table
            rowKey={({ id }) => id}
            onRow={handleOnRow}
            dataSource={documentItems}
            columns={columns}
            pagination={false}
            scroll={{ x: 720 }}
          />

          <ButtonDashed
            icon={<IconPlus width={20} height={20} />}
            onClick={handleAddItem}
            block
          >
            {t('invoiceGenerator.document.buttonAddItem')()}
          </ButtonDashed>

          {helpers.hasIntraEU(documentItems) && (
            <S.ExtraText>
              {t('invoiceGenerator.document.extraTextReverseCharge')()}
            </S.ExtraText>
          )}

          {documentItems.length ? (
            <InvoiceDocumentTotal
              currency={document.documentMetadata?.currency}
              total={helpers.getTotalAmount(documentItems)}
              subTotal={helpers.getSubTotalAmount(documentItems)}
              defaultTaxRate={helpers.getAmountByDefaultTaxRate(documentItems)}
              middleTaxRate={helpers.getAmountByMiddleTaxRate(documentItems)}
            />
          ) : null}
        </S.Table>
      </Flex>

      <Flex gap={60} vertical>
        <Flex gap={24} vertical>
          <InvoiceDocumentFieldEditor
            defaultValue={document.documentMetadata?.notes}
            label={t('invoiceGenerator.document.fieldNotes.label')()}
            placeholder={t(
              'invoiceGenerator.document.fieldNotes.placeholder',
            )()}
            onChange={(notes) =>
              handleUpdateDocumentWithDebounce({
                documentMetadata: {
                  ...document.documentMetadata,
                  notes,
                },
              })
            }
          />

          <InvoiceDocumentFieldEditor
            defaultValue={document.documentMetadata?.termsAndConditions}
            label={t('invoiceGenerator.document.fieldTerms.label')()}
            placeholder={t(
              'invoiceGenerator.document.fieldTerms.placeholder',
            )()}
            onChange={(termsAndConditions) =>
              handleUpdateDocumentWithDebounce({
                documentMetadata: {
                  ...document.documentMetadata,
                  termsAndConditions,
                },
              })
            }
          />
        </Flex>

        <InvoiceDocumentFooter
          bankData={company.details?.bankData}
          contactPerson={company.details?.contactPerson}
          onOpenModalBank={setModalBankAdd}
          onOpenModalContact={setModalContactAdd}
        />
      </Flex>
    </S.Card>
  );
};

export default InvoiceDocumentCard;
