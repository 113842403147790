import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  OnChangeFn,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { Flex, Typography } from 'antd';
import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';

import { IconSortingAsc, IconSortingDefault, IconSortingDesc } from '@assets';
import { useInfiniteScroll } from '@components/InfiniteTable/useInfiniteScroll';

import * as S from './styled';
import { type InfiniteTableRef } from './types';

type TableItem = {
  page: number;
};

const DEFAULT_CELL_HEIGHT = 40;
type InfiniteTableProps<TData extends TableItem> = {
  /**
   * Columns definition for React Table.
   * @see https://tanstack.com/table/v8/docs/api/core/column-def
   */
  columns: ColumnDef<TData>[];
  /**
   * Data to be rendered in the table.
   */
  data: TData[];
  /**
   * Total amount of items in the table.
   */
  totalItems: number;
  /**
   * Amount of items per page.
   */
  perPage: number;
  /**
   * Pages that are currently visible.
   */
  visiblePages: number[];
  /**
   * Whether the table is loading the first time.
   */
  isLoading?: boolean;
  /**
   * Whether the table is fetching the next or prev page.
   */
  isFetching?: boolean;
  /**
   * Height of a single cell.
   */
  cellHeight?: number;
  /**
   * Initial scroll position. Needed for ScrollRestoration component,
   * you don't need to set it manually.
   */
  scrollTop?: number;
  /**
   * Whether the table has more pages.
   */
  hasNextPage?: boolean;
  /**
   * Whether the table has a previous page.
   */
  hasPrevPage?: boolean;
  /**
   * Pages that are currently in a pending state.
   */
  loadingPages?: number[];
  /**
   * The number of pages to render before and after the visible area.
   */
  overscan?: number;
  /**
   * Sorting state of the table.
   */
  sorting?: SortingState;
  /**
   * Handler for detecting the scroll event. Needed for ScrollRestoration component,
   * you don't need to set it manually.
   */
  onScroll?: (event: React.UIEvent<HTMLElement>) => void;
  /**
   * Called when user scrolls to another page
   * @param pages currently visible pages
   */
  onVisiblePagesChange: (pages: number[]) => void;
  /**
   * Handler for clicking on a row.
   * @param event common MouseEvent
   * @param row item that has been clicked
   * @param index position of the item in list
   */
  onRowClick?: (event: React.MouseEvent, row: TData, index: number) => void;
  /**
   * Handler for changing the sorting state.
   */
  onSortChange?: OnChangeFn<SortingState>;
};

const InfiniteTableComponent = <TData extends TableItem>(
  props: InfiniteTableProps<TData>,
  ref: ForwardedRef<InfiniteTableRef>,
) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    resetScroll: () => {
      rootRef.current?.scrollTo({ top: 0 });
    },
    scrollToElement: (index) => {
      rootRef.current?.scrollTo({
        behavior: 'smooth',
        top: index * (props.cellHeight || DEFAULT_CELL_HEIGHT),
      });
    },
  }));

  const table = useReactTable<TData>({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
    enableMultiSort: false,
    state: {
      sorting: props.sorting,
    },
    onSortingChange: props.onSortChange,
    defaultColumn: {
      size: 0,
      minSize: 0,
    },
  });

  const minPage = props.data
    .map(({ page }) => page)
    .reduce((min, page) => Math.min(min, page), Infinity);

  const hasPrevPage = Number.isFinite(minPage) ? minPage > 1 : false;

  const infiniteScroll = useInfiniteScroll({
    height: props.cellHeight ?? DEFAULT_CELL_HEIGHT,
    perPage: props.perPage,
  });

  /**
   * Returns visible pages based on scroll position.
   * @param scrollTop
   * @param clientHeight
   */
  const getVisiblePages = (scrollTop: number, clientHeight: number) => {
    const firstPage =
      Math.floor(
        scrollTop / (props.cellHeight ?? DEFAULT_CELL_HEIGHT) / props.perPage,
      ) + 1;

    const lastPage =
      Math.floor(
        (scrollTop + clientHeight) /
          (props.cellHeight ?? DEFAULT_CELL_HEIGHT) /
          props.perPage,
      ) + 1;

    const visiblePages = Array.from(
      { length: lastPage - firstPage + 1 },
      (_, i) => firstPage + i,
    );

    if (props.visiblePages?.join(',') !== visiblePages.join(',')) {
      props.onVisiblePagesChange(
        visiblePages.filter(
          (page) => page >= 1 && page <= props.totalItems / props.perPage + 1,
        ),
      );
    }
  };

  useEffect(() => {
    if (!rootRef.current) return;

    const { scrollTop, clientHeight } = rootRef.current;

    getVisiblePages(scrollTop, clientHeight);
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    if (rootRef.current && !props.isLoading) {
      rootRef.current.scrollTo(0, props.scrollTop || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isLoading]);

  const renderSortingIcon = (
    canBeSorted: boolean,
    sortingState: 'asc' | 'desc' | false,
  ) => {
    if (!canBeSorted) {
      return null;
    }

    if (sortingState === 'asc') {
      return (
        <S.Icon active={Boolean(sortingState)}>
          <IconSortingAsc />
        </S.Icon>
      );
    }

    if (sortingState === 'desc') {
      return (
        <S.Icon active={Boolean(sortingState)}>
          <IconSortingDesc />
        </S.Icon>
      );
    }

    return (
      <S.Icon>
        <IconSortingDefault />
      </S.Icon>
    );
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight } = event.currentTarget;

    getVisiblePages(scrollTop, clientHeight);

    props.onScroll?.(event);
  };

  const skeletons = new Array(props.perPage).fill(0);

  const tableHeight = infiniteScroll.getTableHeight(props.totalItems);

  /**
   * List of loaded pages based on visible pages + overscan.
   */
  const loadedPages = useMemo(() => {
    const minPage = Math.min(...props.visiblePages) - (props.overscan || 0);
    const maxPage = Math.max(...props.visiblePages) + (props.overscan || 0);

    const visiblePages = Array.from(
      { length: maxPage - minPage + 1 },
      (_, i) => minPage + i,
    );

    return visiblePages.filter(
      (page) => page >= 1 && page <= props.totalItems / props.perPage + 1,
    );
  }, [props.visiblePages, props.overscan, props.totalItems, props.perPage]);

  return (
    <S.Root onScroll={handleScroll} ref={rootRef}>
      <S.Table>
        <S.Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <S.TRow
              style={{ display: 'flex', width: '100%' }}
              key={headerGroup.id}
            >
              {headerGroup.headers.map((header) => (
                <S.Th
                  key={header.id}
                  style={{
                    display: 'flex',
                    width: header.getSize(),
                    flexGrow: header.getSize() === 0 ? 1 : 0,
                  }}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Flex flex={1} justify="space-between" align="center">
                    <Typography.Text strong>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </Typography.Text>
                    {renderSortingIcon(
                      header.column.getCanSort(),
                      header.column.getIsSorted(),
                    )}
                  </Flex>
                </S.Th>
              ))}
            </S.TRow>
          ))}
        </S.Thead>
        <S.TBody
          style={{
            height: tableHeight,
          }}
        >
          {hasPrevPage &&
            skeletons.map((_, index) => (
              <S.TRow
                key={index}
                style={{
                  ...infiniteScroll.getStyle(
                    minPage - 1,
                    index + props.perPage - skeletons.length,
                  ),
                  width: '100%',
                }}
              >
                {table.getHeaderGroups().map((headerGroup) =>
                  headerGroup.headers.map((header) => (
                    <S.Td
                      key={header.id}
                      style={{
                        width: header.getSize(),
                        flexGrow: header.getSize() === 0 ? 1 : 0,
                      }}
                    >
                      <S.Skeleton />
                    </S.Td>
                  )),
                )}
              </S.TRow>
            ))}

          {table
            .getRowModel()
            .rows.filter((row) => loadedPages.includes(row.original.page))
            .map((row, index) => (
              <S.TRow
                key={row.id}
                style={{
                  ...infiniteScroll.getStyle(
                    row.original.page,
                    index % props.perPage,
                  ),
                  width: '100%',
                }}
                onClick={(event) =>
                  props.onRowClick?.(
                    event,
                    row.original,
                    (row.original.page - 1) * props.perPage +
                      (index % props.perPage) +
                      1,
                  )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <S.Td
                    key={cell.id}
                    style={{
                      width: cell.column.getSize(),
                      flexGrow: cell.column.getSize() === 0 ? 1 : 0,
                    }}
                  >
                    <S.TdText>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </S.TdText>
                  </S.Td>
                ))}
              </S.TRow>
            ))}
          {props.loadingPages &&
            props.loadingPages?.map((page) =>
              skeletons.map((_, index) => (
                <S.TRow
                  key={index}
                  style={{
                    ...infiniteScroll.getStyle(page, index),
                    width: '100%',
                  }}
                >
                  {table.getHeaderGroups().map((headerGroup) =>
                    headerGroup.headers.map((header) => (
                      <S.Td
                        key={header.id}
                        style={{
                          width: header.getSize(),
                          flexGrow: header.getSize() === 0 ? 1 : 0,
                        }}
                      >
                        <S.Skeleton />
                      </S.Td>
                    )),
                  )}
                </S.TRow>
              )),
            )}
        </S.TBody>
      </S.Table>
      {props.totalItems === 0 && !props.loadingPages?.length && (
        <Flex justify="center" align="center" style={{ height: '100%' }}>
          <Typography.Text>No data</Typography.Text>
        </Flex>
      )}
    </S.Root>
  );
};

const InfiniteTable = forwardRef(InfiniteTableComponent) as <
  TData extends TableItem,
>(
  props: InfiniteTableProps<TData> & { ref?: ForwardedRef<InfiniteTableRef> },
  ref: ForwardedRef<InfiniteTableRef>,
) => ReturnType<typeof InfiniteTableComponent>;

export default InfiniteTable;
