//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$RuleController_restore,
  ResponseContentType$RuleController_restore,
  Response$RuleController_restore$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useRuleController_restore = () =>
  useApiClientMutation<
    Params$RuleController_restore,
    Response$RuleController_restore$Status$200[ResponseContentType$RuleController_restore]
  >({ method: 'RuleController_restore' });
