import styled from '@emotion/styled';
import { Typography } from 'antd';

const { Text } = Typography;

export const Title = styled(Text)`
  font-size: 16px;
`;

export const Subtitle = styled(Text)`
  font-size: 12px;
`;

export const Icon = styled.span`
  display: inline-flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.grey400};
`;
