import { Flex, message } from 'antd';
import { MouseEvent, ReactNode, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { EmptyTransactions, TransactionContentModal } from '@entities';
import { useAccount } from '@hooks';
import useUpdateTransactionById from '@hooks/api/transactions/useUpdateTransactionById';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { getDefaultValues, removeDefaultOrEmptyValues } from '@utils/zod';
import { useAccountsController_getAccounts } from '@api-client/generated/AccountsController/getAccounts';
import { useTransactionController_findAll } from '@api-client/generated/TransactionController/findAll';
import { useTransactionController_updateOneById } from '@api-client/generated/TransactionController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { useModalManager } from '@context/ModalManager/useModalManager';

import { QueryParams, queryParamsSchema } from './schema';
import * as S from './styled';
import TableWrapper from './TableWrapper';
import { useTransactionsTable } from './useTransactionsTable';

// TODO: temporal solution until contracts are generated as interfaces
type Transaction<T = Schemas.Transaction> = {
  [K in keyof T]: T[K];
};

type Props = {
  queryParams?: Partial<QueryParams>;
  setQueryParams?: (value: Partial<QueryParams>) => void;
  hiddenColumns?: (keyof Transaction)[];
  onAfterModalClose?: () => void;
  filterForm?: (props: {
    onFormUpdate: (values: Partial<QueryParams>) => void;
    total: number;
  }) => ReactNode;
};

function TransactionsTable(props: Props) {
  const { search } = useLocation();
  const { companyId } = useAccount();

  const [modalId, setModalId] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<string>();
  const [detailsPage, setDetailsPage] = useState(1);
  const isTableClickable = !useModalManager().isOpen();

  const {
    metadata,
    incrementPage,
    hasNextPage,
    plainData,
    appendData,
    reset,
    UNSAFE_setData: setTransactionsList,
  } = usePagination<Transaction>(search);

  const [formData, setFormData] = useState<QueryParams>(() => ({
    ...getDefaultValues(queryParamsSchema),
    ...props.queryParams,
  }));

  const { isLoading, refetch, data, isFetched } =
    useTransactionController_findAll({
      // @ts-expect-error fix array params later
      params: {
        ...removeDefaultOrEmptyValues(queryParamsSchema, formData),
        companyId: companyId!,
        page: metadata.currentPage,
      },
    });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  useEffect(() => {
    setFormData({ ...formData, ...props.queryParams });
  }, [props.queryParams]); // eslint-disable-line

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  const { data: accounts } = useAccountsController_getAccounts({
    params: {
      companyId: companyId!,
    },
  });

  const handleCloseContentModal = () => {
    setModalId(null);
    props.onAfterModalClose?.();

    setTimeout(() => {
      setSelectedRow(undefined);
    }, 1000);
  };

  const handleRowClick = useCallback(
    (e: MouseEvent, record: Schemas.Transaction, index: number) => {
      if (!isTableClickable) return;

      if (e.ctrlKey || e.metaKey) {
        window.open(`/transactions/${record.id}`, '_blank');
      } else {
        setModalId(record.id);
      }

      setDetailsPage(index! + 1);
      setSelectedRow(record.id);
    },
    [], // eslint-disable-line
  );

  // TODO fix indirect state modification
  const handleChangeFileIds = (id: string, value: Schemas.Document[] | []) => {
    setTransactionsList((prev) => {
      pages: for (const pageItems of Object.values(prev)) {
        for (const transaction of pageItems) {
          if (transaction.id === id) {
            transaction.documents = value;
            break pages;
          }
        }
      }

      return prev;
    });
  };

  // TODO fix indirect state modification
  const handleChangeSettings = (
    id: string,
    field: 'isFileless' | 'isIgnoredForPL',
    value: boolean,
  ) => {
    setTransactionsList((prev) => {
      pages: for (const pageItems of Object.values(prev)) {
        for (const transaction of pageItems) {
          if (transaction.id === id) {
            transaction[field] = value;
            break pages;
          }
        }
      }

      return prev;
    });
  };

  const { mutate: updateTransaction } = useUpdateTransactionById();

  const assignContact = useCallback(
    (transactionId: string, contact: Schemas.Contact) => {
      updateTransaction(
        {
          parameter: {
            id: transactionId,
            companyId: companyId!,
          },
          requestBody: {
            contact,
          },
        },
        {
          onSuccess: () => {
            refetch();
            message.success(t('transactionsPage.contactWasChanged')());
          },
        },
      );
    },
    [companyId, refetch, updateTransaction],
  );

  const { mutateAsync: updateTransactionAsync } =
    useTransactionController_updateOneById();

  const handleProjectAssign = (transactionId: string, projectId: string) => {
    try {
      updateTransactionAsync({
        parameter: { companyId: companyId!, id: transactionId },
        requestBody: { project: { id: projectId } },
      });
      refetch();
    } catch {
      message.error(t('transactionsPage.messages.projectAssignFailed')());
    }
  };

  const transactionTable = useTransactionsTable({
    onProjectAssign: handleProjectAssign,
    onRulesSubmit: refetch,
    assignContact,
  });

  function handleFormUpdate(values: Partial<QueryParams>) {
    setFormData((formData) => {
      if (formData.term !== values.term) {
        reset();
      }
      return {
        ...formData,
        ...values,
      } as QueryParams;
    });
    if (props.setQueryParams) {
      props.setQueryParams(values);
    }
  }

  function handleChangeSort(
    sort: Pick<QueryParams, 'sortBy' | 'sortDirection'> | null,
  ) {
    if (sort) {
      handleFormUpdate({ ...sort });
    } else {
      // TODO: not really pretty
      handleFormUpdate({
        sortBy: undefined,
        sortDirection: undefined,
      });
    }
  }

  if (!plainData.length && !accounts?.length && isFetched) {
    return <EmptyTransactions />;
  }

  return (
    <Flex gap={24} vertical>
      {modalId && (
        <TransactionContentModal
          id={modalId}
          open={!!modalId}
          onCancel={handleCloseContentModal}
          page={detailsPage}
          filterOptions={{ ...props.queryParams }}
          setSelectedRow={setSelectedRow}
          onChangeTransactionFileList={handleChangeFileIds}
          handleChangeSettings={handleChangeSettings}
          onUpdateTransaction={refetch}
        />
      )}

      {props.filterForm?.({
        onFormUpdate: handleFormUpdate,
        total: metadata.totalRecords,
      })}

      <S.TableContainer>
        <TableWrapper<
          Transaction,
          (typeof transactionTable.sortableColumns)[number]
        >
          initialSort={
            props.queryParams
              ? {
                sortBy: props.queryParams.sortBy,
                sortDirection: props.queryParams.sortDirection,
              }
              : null
          }
          hiddenColumns={props.hiddenColumns}
          alwaysDisplayedColumns={['file']}
          sortableColumns={transactionTable.sortableColumns}
          storageKey="transactions"
          rowKey={(record) => record.id}
          onRow={(transaction, i) => ({
            onClick: (event) => handleRowClick(event, transaction, i as number),
          })}
          dataSource={plainData}
          columns={transactionTable.columns}
          loading={isLoading}
          pagination={false}
          scroll={{ x: 720 }}
          rowClassName={(record) =>
            record.id === selectedRow ? 'highlight-row' : ''
          }
          onChangeSort={handleChangeSort}
        />
        {hasNextPage && <div ref={sentryRef} />}
      </S.TableContainer>
    </Flex>
  );
}

export default TransactionsTable;
