import { Schemas } from '@api-client/generated/types';
import countries from '@data/countries.json';

export const getAddressLine = (address: Schemas.Address): string => {
  if (!address) return '';

  const fullAddress: string[] = [];

  const countryName = address?.countryCode
    ? countries.find((country) => country.code === address.countryCode)?.name
    : null;

  const primaryAddress = [
    address.houseNumber,
    address.addressLine,
    address.street,
  ]
    .filter(Boolean)
    .join(' ');

  if (primaryAddress) {
    fullAddress.push(primaryAddress);
  }

  if (address?.postalCode) {
    fullAddress.push(address.postalCode);
  }

  if (address?.city) {
    fullAddress.push(address.city);
  }

  if (countryName) {
    fullAddress.push(countryName);
  }

  return fullAddress.join(', ');
};
