import _ from 'lodash';
import { z } from 'zod';

/**
 * Retrieves default values from schema and returns them
 * @param schema zod schema with default values
 * @returns object according to that schema
 */
export function getDefaultValues<T extends z.AnyZodObject>(
  schema: T,
): z.infer<T> {
  return Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
}

/**
 * Removes default values according to schema or empty values according to removeEmptyValues helper
 * @param schema zod schema with default values
 * @param object object to clean up
 * @returns cleaned up object
 */
export function removeDefaultOrEmptyValues<
  T extends z.AnyZodObject,
  V extends z.infer<T>,
>(schema: T, object: V): Partial<z.infer<T>> {
  const defaultValues = getDefaultValues(schema);

  return Object.entries(removeEmptyValues(object))
    .filter(([key, value]) => value !== defaultValues[key])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
}

/**
 * Removes empty values (empty arrays/empty objects/null/undefined/'') from object
 * @param data any object
 * @returns object without empty values
 */
export function removeEmptyValues<T extends Record<string, unknown>>(
  data: T,
): Partial<T> {
  const res = _.omitBy<Partial<T>>(
    data,
    (value) =>
      value === '' ||
      _.isNil(value) ||
      (typeof value === 'object' && _.isEmpty(value)),
  );
  return res;
}
