import React from 'react';
import { ScrollRestorationContext } from '@components/InfiniteTable/context';

export const useScrollContext = () => {
  const context = React.useContext(ScrollRestorationContext);
  if (!context) {
    return {
      getPage: () => 1,
    };
  }
  return context;
};
