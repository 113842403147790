import { Flex } from 'antd';
import { FC, MouseEvent, useState } from 'react';

import { IconPlus } from '@assets';
import { ContactAction, ContactsPopupList } from '@entities';
import { ButtonDashed } from '@components';
import { getAddressLine } from '@utils';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Contact = Schemas.Contact;

type InvoiceDocumentDetailsToProps = {
  contact: Contact | null;
  onSelect: (contact: Contact) => void;
};

const InvoiceDocumentDetailsTo: FC<InvoiceDocumentDetailsToProps> = ({
  contact,
  onSelect,
}) => {
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);

  const handleEditContact = (e: MouseEvent) => {
    e.stopPropagation();
    setIsModalEditVisible(true);
  };

  const handleAfterEditContact = (contact: Contact) => {
    setIsModalEditVisible(false);
    onSelect(contact);
  };

  if (contact) {
    return (
      <>
        <ContactAction
          type="edit"
          id={contact.id}
          open={isModalEditVisible}
          details={contact}
          onAfterAction={handleAfterEditContact}
          onCancel={() => setIsModalEditVisible(false)}
        />

        <ContactsPopupList
          placement="bottomLeft"
          selectedId={contact?.id}
          onSelect={onSelect}
        >
          <S.Details gap={8} filled vertical>
            <S.DetailsTitle>
              {t('invoiceGenerator.document.to')()}
            </S.DetailsTitle>

            <Flex align="flex-start" vertical>
              <S.DetailsType>{contact.name}</S.DetailsType>

              <S.DetailsInner align="flex-start" vertical>
                {contact?.vatId && (
                  <div>
                    {t('invoiceGenerator.document.vatId')()}: {contact.vatId}
                  </div>
                )}

                {contact?.taxNumber && (
                  <div>
                    {t('invoiceGenerator.document.taxNumber')()}:{' '}
                    {contact.taxNumber}
                  </div>
                )}

                <div>{getAddressLine(contact?.billingAddress)}</div>
              </S.DetailsInner>

              <S.Edit onClick={handleEditContact}>
                {t('invoiceGenerator.document.buttonEdit')()}
              </S.Edit>
            </Flex>
          </S.Details>
        </ContactsPopupList>
      </>
    );
  }

  return (
    <S.Details gap={8} vertical>
      <S.DetailsTitle>{t('invoiceGenerator.document.to')()}</S.DetailsTitle>

      <ContactsPopupList onSelect={onSelect}>
        <ButtonDashed
          icon={<IconPlus width={20} height={20} />}
          height={115}
          block
        >
          {t('invoiceGenerator.document.buttonAddContactInformation')()}
        </ButtonDashed>
      </ContactsPopupList>
    </S.Details>
  );
};

export default InvoiceDocumentDetailsTo;
