//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$AccountsController_createAccount,
  ResponseContentType$AccountsController_createAccount,
  Response$AccountsController_createAccount$Status$201,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useAccountsController_createAccount = () =>
  useApiClientMutation<
    Params$AccountsController_createAccount,
    Response$AccountsController_createAccount$Status$201[ResponseContentType$AccountsController_createAccount]
  >({ method: 'AccountsController_createAccount' });
