import i18next from 'i18next';

import { Schemas } from '@api-client/generated/types';

/** Statuses that uses frontend a bit different from backend ones */
export enum ProjectStatus {
  Active = 'active',
  Archived = 'archived',
}

export type Project = Schemas.ProjectResponseDto;

/** Map backend status to frontend ProjectStatus */
export const toFrontendProjectStatus: Record<Project['status'], ProjectStatus> =
  {
    active: ProjectStatus.Active,
    inactive: ProjectStatus.Archived,
    finished: ProjectStatus.Archived,
  };

export const toBackendProjectStatus: Record<ProjectStatus, Project['status']> =
  {
    [ProjectStatus.Archived]: 'inactive',
    [ProjectStatus.Active]: 'active',
  };

export type ProjectListTabs = 'all' | ProjectStatus;

export type ProjectFormValues = {
  name: string;
  description: string;
};

export  const getTransactionsCountString = (project: Project): string => {
  const count = project.transactionsCount || 0;
  const intl = new Intl.PluralRules(i18next.language);
  const amount = intl.select(count);

  // @ts-expect-error move it to a helper of some kind
  return `${count} ${t(`common.plural.transaction.${amount}`)()}`;
}