import { Flex } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';

import { useDocumentController_findAll } from '@api-client/generated/DocumentController/findAll';
import { Schemas } from '@api-client/generated/types';
import { IconClose, IconTrash } from '@assets';
import { Select } from '@components';
import { AttachDropdownItem, TransactionFilePreviewModal } from '@entities';
import { useAccount } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { colors } from '@theme';
import { getCurrencySymbol } from '@utils';

import * as S from './styled';

type CornerCardProps = {
  type: 'hovering' | 'selectable';
  transaction: Schemas.Transaction;
  file: Schemas.Document;
  selectedCard?: Schemas.Document | null;
  onDetach?: () => void;
  onDelete?: () => void;
  onRefresh?: () => void;
  onClick?: () => void;
  onAddExistingFile?: (file: Schemas.Document) => void;
  onListUpdate: (file: Schemas.Document) => void;
  onCancelUpload: (id: string) => void;
};

type UsePaginatedTransactionListParams = {
  companyId: string;
  term?: string;
  isEnabled: boolean;
  transaction: Schemas.Transaction;
};

const usePaginatedTransactionsList = (
  params: UsePaginatedTransactionListParams
) => {
  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<Schemas.Document>(params.term);

  const { isPending: isLoading, data } = useDocumentController_findAll({
    params: {
      page: metadata.currentPage,
      companyId: params.companyId,
      term: params.term,
      type:
        params.transaction.amount > 0 ? 'income_document' : 'expence_document',
    },
    config: {
      enabled: params.isEnabled,
    },
  });

  useEffect(() => {
    if (data) {
      appendData(data);
    }
  }, [appendData, data]);

  const { sentryRef } = useInfiniteScroll({
    isLoading,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  return {
    isLoading,
    plainData,
    sentryRef,
    hasNextPage,
  };
};

const CornerCard: FC<CornerCardProps> = ({
  type,
  transaction,
  file,
  selectedCard,
  onDetach,
  onDelete,
  onRefresh,
  onAddExistingFile,
  onListUpdate,
  onCancelUpload,
  onClick,
}) => {
  const { companyId } = useAccount();
  const [isViewMode, setIsViewMode] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const amount = file.documentMetadata?.amount || 0;
  const currencyCode = file.documentMetadata?.currency || 'EUR';
  const isNew = file.id === 'new';
  const isIncomeExpence = Boolean(
    file?.type === 'income_document' || 'expence_document'
  );

  const { isLoading, plainData, hasNextPage, sentryRef } =
    usePaginatedTransactionsList({
      companyId: companyId!,
      term: searchValue,
      isEnabled: isNew && isIncomeExpence,
      transaction,
    });

  const options = plainData.map((document) => ({
    ...document,
    label: document.contact?.name,
    value: document.id,
  }));

  const optionRender = (
    oriOption: DefaultOptionType,
    { index }: { index: number }
  ) => (
    <>
      <AttachDropdownItem
        item={oriOption.data as Schemas.Document}
        width="100%"
        height="121px"
        key={oriOption.key}
      />
      {index === options.length - 1 && hasNextPage && (
        <div ref={sentryRef}></div>
      )}
    </>
  );

  return (
    <S.Wrapper
      type={
        file?.id === selectedCard?.id
          ? 'selected'
          : type === 'hovering' && !isNew
            ? 'hovering'
            : 'selectable'
      }
      onClick={onClick}
    >
      <S.CornerCard
        type={
          file?.id === selectedCard?.id
            ? 'selected'
            : type === 'hovering' && !isNew
              ? 'hovering'
              : 'selectable'
        }
      />
      <S.TextBlock gap={19} justify="space-between" vertical>
        {isNew && isIncomeExpence ? (
          <span>{t('common.cornerCard.attach')()}</span>
        ) : (
          <Flex gap={6} vertical>
            <S.CornerCardAdditionalTitle>
              {t('common.cornerCard.paymentDocument')()}
            </S.CornerCardAdditionalTitle>
            <S.CornerCardTitle>
              {file?.contact?.name ||
                transaction?.contact?.name ||
                t('common.cornerCard.noContact')()}
            </S.CornerCardTitle>
            <S.CornerCardDescription>
              {file.documentMetadata?.description || <div />}
            </S.CornerCardDescription>
          </Flex>
        )}

        {isNew && isIncomeExpence && (
          <Select
            autoFocus={isNew}
            options={options}
            optionRender={optionRender}
            loading={isLoading}
            listWidth={333}
            width={293}
            onSearch={setSearchValue}
            className="ui-attach-select"
            placeholder={t('common.cornerCard.paymentDocument')()}
            onChange={(_, option) => {
              onAddExistingFile?.(option as Schemas.Document);
            }}
            dropdownRender={(menu) => (
              <div className="ui-attach-select-dropdown">{menu}</div>
            )}
            allowClear={{ clearIcon: <IconClose width={20} height={20} /> }}
            dropdownAlign={{
              points: ['tl', 'bl'],
              offset: [-20, 0],
            }}
            filterOption={false}
            defaultOpen
            showSearch
          />
        )}

        {isNew && isIncomeExpence ? (
          <Flex justify="flex-start" align="center">
            <S.DetachButton type="text" onClick={onDelete}>
              <IconTrash color={colors.error} />
              {t('common.actions.delete')()}
            </S.DetachButton>
          </Flex>
        ) : (
          <S.Footer justify="space-between">
            <S.CornerCardPrice className="price">{`${amount < 0 ? '-' + getCurrencySymbol(currencyCode) + Math.abs(amount).toFixed(2) : getCurrencySymbol(currencyCode) + amount}`}</S.CornerCardPrice>
            <S.CornerCardDate className="date">
              {dayjs(file.documentMetadata?.issueDate).format('DD.MM.YYYY')}
            </S.CornerCardDate>

            <S.Actions className="actions">
              <S.ViewButton type="text" onClick={() => setIsViewMode(true)}>
                {t('common.actions.view')()}
              </S.ViewButton>

              <S.DetachButton type="text" onClick={onDetach}>
                {t('common.actions.detach')()}
              </S.DetachButton>
            </S.Actions>
          </S.Footer>
        )}
      </S.TextBlock>

      <TransactionFilePreviewModal
        type={transaction?.amount > 0 ? 'income' : 'expenses'}
        open={isViewMode}
        onClose={() => setIsViewMode(false)}
        file={file}
        onRefresh={onRefresh}
        onListUpdate={onListUpdate}
        onCancelUpload={onCancelUpload}
      />
    </S.Wrapper>
  );
};

export default CornerCard;
