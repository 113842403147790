import type { ThemeConfig } from 'antd';

import { colorPalette } from '..';

const tooltipTokens: ThemeConfig['components'] = {
  Tooltip: {
    colorBgSpotlight: colorPalette.blue500,
    colorText: colorPalette.white,
    controlHeight: 32,
    fontSize: 12,
  },
};

export default tooltipTokens;
