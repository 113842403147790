import { ThemeProvider } from '@ui';

import SettingsPersonal from './SettingsPersonal';

const SettingsPersonalPage = () => (
  <ThemeProvider>
    <SettingsPersonal />
  </ThemeProvider>
);

export default SettingsPersonalPage;
