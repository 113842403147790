import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Root = styled(Flex)`
  height: calc(100% - 48px);
`;

export const AmountCell = styled.div`
  width: 100%;
  text-align: end;
`;

export const ExcludedTransaction = styled.span`
  color: ${({ theme }) => theme.colors.text200};
`;
