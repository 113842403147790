import { Spin } from 'antd';
import { rgba } from 'emotion-rgba';
import { FC, useEffect, useState } from 'react';

import { Schemas } from '@api-client/generated/types';
import styled from '@emotion/styled';
import { useAppState } from '@hooks';
import { wrapTextLinesInParagraphs } from '@utils';

import MessagesList from '../MessagesList/MessagesList';
import AddMessage from './AddMessage';
import Header from './Header';
import UploadFiles from './UploadFiles';
import { useSocketChat } from './useSocketChat';

const Container = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.darkgrey};
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 3px 8px 0 ${({ theme }) => rgba(theme.colors.dark, 0.1)};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Inner = styled.div`
  padding: 22px 30px 30px 30px;
`;

type TasksChatProps = {
  taskId: string;
  details: Schemas.Task;
};

const ChatContent: FC<TasksChatProps> = ({ taskId, details }) => {
  const [modalAttachVisible, setModalAttachVisible] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [disabledForm, setDisabledForm] = useState(true);

  const {
    isPayrollPlaceholderMessage,
    updateVisibilityPayrollPlaceholderMessage,
  } = useAppState();

  const {
    messages,
    isLoading,
    createMessage,
    deleteMessage,
    markAsSeenMessage,
  } = useSocketChat({
    taskId,
  });

  useEffect(() => {
    if (isPayrollPlaceholderMessage) {
      setNewMessage(t('payroll.chat.placeholderMessage')());
      setDisabledForm(false);
    } else {
      setNewMessage('');
      setDisabledForm(true);
    }

    return () => {
      setNewMessage('');
      setDisabledForm(true);
      updateVisibilityPayrollPlaceholderMessage(false);
    };
  }, [
    taskId,
    isPayrollPlaceholderMessage,
    updateVisibilityPayrollPlaceholderMessage,
  ]);

  const handleCreateMessage = () => {
    if (newMessage) {
      createMessage(wrapTextLinesInParagraphs(newMessage));
      setNewMessage('');
    }

    setDisabledForm(true);
    updateVisibilityPayrollPlaceholderMessage(false);
  };

  const handleChangeMessage = (message: string) => {
    setNewMessage(message);
    setDisabledForm(!message);
  };

  return (
    <>
      <Container>
        <Header
          title={details.isGeneral ? t('chat.generalTitle')() : details.title}
          type={
            details.isGeneral
              ? 'chat'
              : details.closedAt
                ? 'resolvedTask'
                : 'openTask'
          }
        />

        <Spin spinning={isLoading}>
          <MessagesList
            selectedId={taskId}
            dataSource={messages}
            onSeenMessages={markAsSeenMessage}
            onRemove={deleteMessage}
          />
        </Spin>

        <Inner>
          <AddMessage
            isDisabled={disabledForm}
            onAttach={() => setModalAttachVisible(true)}
            onSend={handleCreateMessage}
            onChange={handleChangeMessage}
            value={newMessage}
          />
        </Inner>
      </Container>

      <UploadFiles
        actionCreateMessage={createMessage}
        selectedId={taskId}
        open={modalAttachVisible}
        onCancel={() => setModalAttachVisible(false)}
      />
    </>
  );
};

export default ChatContent;
