//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$FileMappingController_import,
  ResponseContentType$FileMappingController_import,
  Response$FileMappingController_import$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useFileMappingController_import = () =>
  useApiClientMutation<
    Params$FileMappingController_import,
    Response$FileMappingController_import$Status$200[ResponseContentType$FileMappingController_import]
  >({ method: 'FileMappingController_import' });
