//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$CategoryGroupController_delete,
  ResponseContentType$CategoryGroupController_delete,
  Response$CategoryGroupController_delete$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useCategoryGroupController_delete = () =>
  useApiClientMutation<
    Params$CategoryGroupController_delete,
    Response$CategoryGroupController_delete$Status$200[ResponseContentType$CategoryGroupController_delete]
  >({ method: 'CategoryGroupController_delete' });
