import styled from '@emotion/styled';
import { Flex, type FlexProps, Typography } from 'antd';

export const Header = styled(Flex)<FlexProps>`
  margin-bottom: 12px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 27px;
    margin-bottom: 0;
  }

  .ui-input {
    width: 100%;
    min-width: 200px;
    height: 33px;
    font-size: 27px;
    font-weight: 600;
  }
`;

export const Name = styled.div`
  width: 130px;
`;

export const SubTitle = styled(Typography.Title)`
  && {
    &.ui-typography {
      font-size: 16px;
      margin: 0;
    }
  }
`;
