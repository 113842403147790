import styled from '@emotion/styled';
import { Flex, Typography } from 'antd';

export const Root = styled(Flex)`
  height: calc(100vh - 175px);
`;

export const Title = styled(Typography.Title)`
  && {
    --ui-typography-title-margin-top: 0;
    --ui-typography-title-margin-bottom: 0;
  }
`
