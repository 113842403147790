import { Button, Form, Input, message, Modal } from 'antd';
import { useEffect } from 'react';

import { Project } from '..';
import { useProjectCreate, useProjectEdit } from '../hooks';
import * as S from './styled';

type ProjectForm = {
  name: string;
  description: string;
};
type ProjectFormProps = {
  actionType: 'edit' | 'create';
  project?: Project | null;
  isOpened: boolean;
  onAfterSubmit: (projectId: string) => void;
  onCancel: VoidFunction;
};

const ProjectForm = (props: ProjectFormProps) => {
  const [form] = Form.useForm<ProjectForm>();

  const { mutateAsync: editProject, isPending: isEditPending } =
    useProjectEdit();
  const { mutateAsync: createProject, isPending: isCreatePending } =
    useProjectCreate();

  const isPending = isEditPending || isCreatePending;

  useEffect(() => {
    // antd form can't update fields values when it's needed
    form.resetFields();
  }, [props.actionType, props.project]); // eslint-disable-line

  const getFormValuesForRequest = () => ({
    name: form.getFieldValue('name'),
    notes: form.getFieldValue('description'),
  });

  const handleEdit = async () => {
    if (!props.project) return;

    try {
      await form.validateFields();
    } catch {
      return;
    }

    try {
      // @ts-expect-error to fix this error we need to fix that on backend
      const project = await editProject({
        ...props.project,
        ...getFormValuesForRequest(),
      });
      props.onAfterSubmit(project.id);
      form.resetFields();
    } catch (e) {
      message.error(t('projects.error.edit')());
      console.error(e);
    }
  };

  const handleCreate = async () => {
    try {
      await form.validateFields();
    } catch {
      return;
    }

    try {
      const project = await createProject({
        ...getFormValuesForRequest(),
        status: 'active',
      });
      props.onAfterSubmit(project.id);
      form.resetFields();
    } catch (e) {
      message.error(t('projects.error.create')());
      console.error(e);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const handleSubmit = () => {
    if (props.actionType === 'edit') {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  return (
    <Modal
      forceRender
      open={props.isOpened}
      onCancel={props.onCancel}
      closable={!isPending}
      title={
        props.actionType === 'edit'
          ? t('projects.form.title.edit', { name: props.project?.name })()
          : t('projects.form.title.create')()
      }
      footer={[
        <Button
          key="cancel"
          size="small"
          disabled={isPending}
          onClick={handleCancel}
        >
          {t('common.actions.cancel')()}
        </Button>,

        <Button
          key="submit"
          form="project-form"
          type="primary"
          htmlType="submit"
          size="small"
          disabled={isPending}
          onClick={handleSubmit}
        >
          {props.actionType === 'edit'
            ? t('common.actions.edit')()
            : t('common.actions.add')()}
        </Button>,
      ]}
    >
      <Form
        form={form}
        id="project-form"
        layout="vertical"
        validateTrigger="onBlur"
      >
        <S.FormItem
          name="name"
          label={t('projects.properties.name')()}
          initialValue={props.project?.name || ''}
          rules={[
            {
              required: true,
              min: 3,
              message: t('projects.form.message.nameRequired')(),
            },
          ]}
          hasFeedback
        >
          <Input
            size="large"
            placeholder={t('projects.form.placeholder.name')()}
          />
        </S.FormItem>

        <S.FormItem
          name="description"
          label={t('projects.properties.description')()}
          initialValue={props.project?.notes || ''}
        >
          <Input.TextArea
            size="large"
            placeholder={t('projects.form.placeholder.description')()}
          />
        </S.FormItem>
      </Form>
    </Modal>
  );
};

export default ProjectForm;
