import { ApiTypes } from '@api';
import type { TableColumnType } from 'antd';

import { Button } from '@ui-kit/Button/Button';
import { IconDownload } from '@assets';
import { Amount, DateText, StatusTag } from '@entities';
import { downloadFile } from '@utils';
import { DEFAULT_CURRENCY_CODE } from '@constants';

export const getTableColumns =
  (): TableColumnType<ApiTypes.BillingDocument>[] => [
    {
      key: 'issueDate',
      dataIndex: 'documentMetadata',
      title: t('settings.billing.billingHistory.table.issueDate')(),
      render: (data) =>
        data?.issueDate && <DateText date={data.issueDate} format="date" />,
    },
    {
      key: 'dueDate',
      dataIndex: 'documentMetadata',
      title: t('settings.billing.billingHistory.table.dueDate')(),
      render: (data) =>
        data?.dueDate && <DateText date={data.dueDate} format="date" />,
    },
    {
      key: 'number',
      dataIndex: 'documentMetadata',
      title: t('settings.billing.billingHistory.table.reference')(),
      render: (data) => data?.number,
    },
    {
      key: 'amount',
      dataIndex: 'documentMetadata',
      title: t('settings.billing.billingHistory.table.amount')(),
      render: (data) =>
        data?.amount && (
          <Amount
            amount={data.amount}
            currencyCode={data?.currencyCode || DEFAULT_CURRENCY_CODE}
          />
        ),
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: t('settings.billing.billingHistory.table.status')(),
      render: (status) => <StatusTag status={status} />,
    },
    {
      key: 'actions',
      dataIndex: 'url',
      width: 48,
      render: (url, record) => (
        <Button
          type="link"
          variant="link"
          size="middle"
          icon={<IconDownload />}
          onClick={() =>
            downloadFile(
              url,
              record.documentMetadata?.number || record.name,
              true,
            )
          }
        />
      ),
    },
  ];
