import type { ThemeConfig } from 'antd';

import { colorPalette } from '..';

const inputTokens: ThemeConfig['components'] = {
  Input: {
    borderRadiusLG: 6,
    controlHeightLG: 44,
    colorBorder: colorPalette.grey200,
    inputFontSizeLG: 14,
  },
};

export default inputTokens;
