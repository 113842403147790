import { Dropdown } from 'antd';
import { rgba } from 'emotion-rgba';
import { memo, MouseEvent, useCallback, useId } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconMoreVertical } from '@assets';
import { useModalManager } from '@context/ModalManager/useModalManager';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ContactsPopupList } from '@entities';
import { Button } from '@ui-kit/Button/Button';

import { useGetRules } from '../../rules/useGetRules';

const HoverableButton = styled('div')();

const Invisible = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
`;

const Wrapper = styled('div', {
  shouldForwardProp: (prop) =>
    !['isDropdownOpen', 'isContactSelectorOpen', 'hasContact'].includes(prop),
})<{
  isDropdownOpen: boolean;
  isContactSelectorOpen: boolean;
  hasContact: boolean;
}>`
  justify-content: space-between;
  align-items: center;
  height: inherit;
  padding: 16px;
  margin: 0;
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  
  & > span {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  background-color: ${({ isDropdownOpen, theme }) =>
    isDropdownOpen && theme.colors.grey100};
  background-color: ${({ hasContact, theme }) =>
    !hasContact && theme.colors.yellow200};
  color: ${({ hasContact, theme }) => !hasContact && theme.colors.grey500};

  ${({ isContactSelectorOpen, theme }) =>
    isContactSelectorOpen &&
    css`
      border-radius: 6px;
      outline: 2px solid ${theme.colors.grey300};
      outline-offset: -2px;
      background: ${theme.colors.grey100};
      box-shadow: 0px 3px 8px 0px ${rgba(theme.colors.dark, 0.1)};
    `}

  ${HoverableButton} {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.grey100};
    visibility: ${({ isDropdownOpen }) =>
      isDropdownOpen ? 'visible' : 'hidden'};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey100};

    ${HoverableButton} {
      background-color: ${({ theme }) => theme.colors.grey100};
      visibility: ${({ isContactSelectorOpen }) =>
        isContactSelectorOpen ? 'hidden' : 'visible'};
    }
  }
`;

const stopPropagation = (event: React.MouseEvent) => event.stopPropagation();

const NoPropagation = ({ children }: { children: React.ReactNode }) => (
  <div onMouseDown={stopPropagation}>{children}</div>
);

export const ContactCell = memo(
  ({
    contact,
    rawContactName,
    assignContact,
    onRulesSubmit,
  }: {
    contact: Schemas.Contact | null;
    rawContactName: string | null;
    assignContact: (contact: Schemas.Contact) => void;
    onRulesSubmit: () => void;
  }) => {
    const dropdown = useId();
    const contactSelector = useId();

    const modalManager = useModalManager();

    const isDropdownVisible = modalManager.isOpen(dropdown);
    const isContactSelectorVisible = modalManager.isOpen(contactSelector);

    const { setStep, setTransactionsState, setConfig } = useGetRules();

    const openRulesModal = useCallback(() => {
      setConfig({
        onSuccess: onRulesSubmit,
        tabs: ['transaction'],
      });

      setTransactionsState({
        conditions: [
          {
            attribute: 'contact_name',
            operator: 'contains',
            value: contact?.name || rawContactName || '',
          },
        ],
        actions: [{ name: 'assign_contact' }],
      });

      setStep('form');
    }, [
      contact?.name,
      rawContactName,
      onRulesSubmit,
      setConfig,
      setStep,
      setTransactionsState,
    ]);

    const items = {
      items: [
        {
          key: 'assignContact',
          label: t(
            contact
              ? 'transactionsPage.contactActions.replaceContact'
              : 'transactionsPage.contactActions.assignContact',
          )(),
          onClick: () => modalManager.replace(contactSelector),
        },
        {
          key: 'createRule',
          label: t('transactionsPage.contactActions.createRule')(),
          onClick: () => {
            modalManager.pop(dropdown);
            openRulesModal();
          },
        },
      ],
    } satisfies Parameters<typeof Dropdown>[0]['menu'];

    const handleAssignContact: typeof assignContact = useCallback(
      (contact) => {
        assignContact(contact);
        modalManager.pop(contactSelector);
      },
      [assignContact, contactSelector, modalManager],
    );

    function stopEventPropagation(event: MouseEvent<HTMLDivElement>): void {
      event.stopPropagation();
    }

    return (
      <Wrapper
        isDropdownOpen={isDropdownVisible}
        isContactSelectorOpen={isContactSelectorVisible}
        hasContact={!!contact}
        onClick={stopEventPropagation}
      >
        <span>
          {contact?.name || rawContactName || t('transactionsPage.noContact')()}
        </span>
        <Dropdown
          dropdownRender={(menu) => <NoPropagation>{menu}</NoPropagation>}
          menu={items}
          open={isDropdownVisible}
          onOpenChange={modalManager.createSetModalVisibility(dropdown)}
          trigger={['click']}
        >
          <HoverableButton onMouseDown={stopPropagation}>
            <Button type="text" size="xs" icon={<IconMoreVertical />} />
          </HoverableButton>
        </Dropdown>
        <ContactsPopupList
          onSelect={handleAssignContact}
          open={isContactSelectorVisible}
          onOpenChange={modalManager.createSetModalVisibility(contactSelector)}
          placement="bottomLeft"
        >
          <Invisible />
        </ContactsPopupList>
      </Wrapper>
    );
  },
);
