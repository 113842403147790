import { Flex, Input } from 'antd';
import { FC, KeyboardEvent } from 'react';

import { IconAttach } from '@assets';
import styled from '@emotion/styled';
import { Button } from '@ui-kit/Button/Button';

const Container = styled(Flex)`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkgrey};
  background: ${({ theme }) => theme.colors.white};
  padding: 16px;
`;

const Field = styled(Input.TextArea)`
  flex: 1;
  padding-inline: 0;
  padding-top: 0;

  ::placeholder {
    color: ${({ theme }) => theme.colors.blue400};
  }
`;

const AttachFile = styled(IconAttach)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.grey300};
`;

type AddMessageProps = {
  onAttach: VoidFunction;
  onChange: (value: string) => void;
  onSend: VoidFunction;
  value: string;
  isDisabled?: boolean;
};

const AddMessage: FC<AddMessageProps> = ({
  onAttach,
  onChange,
  onSend,
  value,
  isDisabled,
}) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <Container align="top" gap={16}>
      <AttachFile onClick={onAttach} />

      <Field
        variant="borderless"
        size="large"
        placeholder={t('tasks.chat.fieldMessage.placeholder')()}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        autoSize={{ minRows: 1, maxRows: 5 }}
      />

      <Button
        type="primary"
        onClick={onSend}
        size="small"
        disabled={isDisabled}
      >
        {t('chatLive.buttonSend')()}
      </Button>
    </Container>
  );
};

export default AddMessage;
