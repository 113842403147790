import styled from '@emotion/styled';
import { Flex, type FlexProps } from 'antd';

export const Container = styled(Flex)<FlexProps>`
  margin-top: 20px;
`;

export const Total = styled(Flex)<FlexProps>`
  min-width: 250px;
`;

export const SecondaryLabel = styled.div`
  color: ${({ theme }) => theme.colors.text400};
  font-weight: 600;
  font-size: 13px;
  margin-right: 16px;
`;

export const SecondaryValue = styled.div`
  font-size: 13px;

  .ui-typography {
    font-size: 13px;
  }
`;

export const PrimaryLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-right: 16px;
`;

export const PrimaryValue = styled.div`
  font-size: 15px;
  font-weight: 600;

  .ui-typography {
    font-size: 15px;
    font-weight: 600;
  }
`;
