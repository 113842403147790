//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$CompanyController_updateOneById,
  ResponseContentType$CompanyController_updateOneById,
  Response$CompanyController_updateOneById$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useCompanyController_updateOneById = () =>
  useApiClientMutation<
    Params$CompanyController_updateOneById,
    Response$CompanyController_updateOneById$Status$200[ResponseContentType$CompanyController_updateOneById]
  >({ method: 'CompanyController_updateOneById' });
