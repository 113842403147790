import { css } from '@emotion/react';
import { colors } from '@ui-kit/theme/theme';

export const buttonStyles = css`
  .ui-btn.ui-btn {
    // ant variables
    --ui-control-height: 48px;
    --ui-control-height-lg: 56px;
    --ui-control-height-sm: 40px;
    --ui-border-radius: 12px;
    --ui-border-radius-lg: 12px;
    --ui-border-radius-sm: 12px;
    --ui-button-padding-inline: 28px;
    --ui-button-padding-inline-lg: 28px;
    --ui-button-padding-inline-sm: 20px;
    --ui-button-content-font-size: 16px;
    --ui-button-content-font-size-sm: 14px;
    --ui-button-content-font-size-lg: 16px;
    --ui-button-font-weight: 600;
    --ui-button-border-color-disabled: transparent;

    --ui-button-default-color: var(--ui-color-primary);
    --ui-button-default-hover-color: var(--ui-color-text);
    --ui-button-default-hover-border-color: var(--ui-color-text);
    --ui-button-default-active-color: var(--ui-color-text);
    --ui-button-default-active-border-color: var(--ui-color-text);

    --ui-button-text-text-color: var(--ui-color-primary);
    --ui-button-text-hover-bg: ${colors.darkgrey};

    --ui-color-primary-hover: ${colors.blue500};
    --ui-color-primary-active: ${colors.blue500};

    --ui-color-link-hover: var(--ui-color-text);
    --ui-color-link-active: var(--ui-color-text);

    --ui-color-text-secondary: var(--ui-color-text);
    --ui-color-text-disabled: ${colors.grey300};
    --ui-color-bg-container-disabled: ${colors.darkgrey};
    --ui-color-bg-text-active: ${colors.darkgrey};

    // custom variables

    --ui-icon-size: 20px;
    --ui-button-padding-inline-start: var(--ui-button-padding-inline);
    --ui-button-padding-inline-end: var(--ui-button-padding-inline);
    --ui-line-height: 24px;
    --ui-border-color: transparent;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    padding-inline-start: var(--ui-button-padding-inline-start);
    padding-inline-end: var(--ui-button-padding-inline-end);
    line-height: var(--ui-line-height);

    &:has(.ui-btn-icon) {
      --ui-button-padding-inline-start: calc(
        var(--ui-button-padding-inline) - 4px
      );

      &.ui-btn-icon-end {
        --ui-button-padding-inline-start: var(--ui-button-padding-inline);
        --ui-button-padding-inline-end: calc(
          var(--ui-button-padding-inline) - 4px
        );
      }

      &.ui-btn-icon-only {
        --ui-button-padding-inline-start: 0;
        --ui-button-padding-inline-end: 0;
      }
    }

    & > .ui-btn-icon,
    & > .ui-btn-icon svg {
      height: var(--ui-icon-size);
      width: var(--ui-icon-size);
    }

    &.ui-btn-sm {
      --ui-icon-size: 16px;
      --ui-button-padding-inline-start: var(--ui-button-padding-inline-sm);
      --ui-button-padding-inline-end: var(--ui-button-padding-inline-sm);
      --ui-line-height: 22px;
    }

    &.ui-btn-lg {
      --ui-line-height: 24px;
    }

    & > .ui-btn-icon svg * {
      vector-effect: non-scaling-stroke;
    }

    &.ui-btn-default {
      --ui-border-color: var(--ui-button-default-color);

      border: 2px solid var(--ui-border-color);
      background-color: transparent;

      &:hover,
      &:active {
        --ui-button-default-color: var(--ui-color-text);
      }

      &[disabled] {
        --ui-button-default-color: var(--ui-color-text-disabled);
      }
    }

    &.ui-btn-variant-filled {
      --ui-button-font-weight: 500;
      --ui-button-padding-inline-sm: 12px;

      --ui-icon-size: 20px;

      border: none;
      background-color: ${colors.grey100};
      color: ${colors.grey500};

      &&:hover:not(:disabled),
      &&:active:not(:disabled) {
        background-color: var(--ui-color-primary);
        color: ${colors.white};
      }

      &[disabled] {
        border-color: var(--ui-color-text-disabled);
        color: var(--ui-color-text-disabled);
      }
    }

    &.ui-btn-link {
      --ui-control-height: 32px;
      --ui-control-height-sm: 30px;
      --ui-border-radius: 0;
      --ui-border-radius-sm: 0;
      --ui-button-padding-inline: 0;
      --ui-button-padding-inline-lg: 0;
      --ui-button-padding-inline-sm: 0;

      display: inline-flex;

      line-height: 24px;

      &.ui-btn-icon-only {
        --ui-color-link: ${colors.grey500};
        --ui-color-link-hover: var(--ui-color-primary);
        --ui-color-link-active: var(--ui-color-primary);
      }
    }

    &.ui-btn-circle {
      &.ui-btn-sm {
        --ui-icon-size: 20px;
      }

      &.ui-btn-lg {
        --ui-icon-size: 32px;
      }
    }

    &.ui-btn-color-dangerous {
      --ui-button-danger-shadow: none;
      --ui-color-error: ${colors.red600};
      --ui-color-error-hover: ${colors.red500};
      --ui-color-error-active: var(--ui-color-error-hover);
      --ui-color-error-bg-active: ${colors.red300};

      &.ui-btn-default {
        --ui-button-default-color: var(--ui-color-error);
        --ui-border-color: var(--ui-button-default-color);

        &:hover:not(:disabled),
        &:active:not(:disabled) {
          --ui-color-error-border-hover: var(--ui-color-error-hover);
        }

        &[disabled] {
          --ui-button-default-color: var(--ui-color-text-disabled);
        }
      }
    }
  }
`;
