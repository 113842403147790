import { Flex } from 'antd';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

type Type = 'chat' | 'openTask' | 'resolvedTask';

const Wrapper = styled(Flex)`
  height: 56px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.darkgrey};
  padding: 16px 30px;
  background: ${({ theme }) => theme.colors.grey100};
  border-radius: 10px 10px 0 0;
`;

const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.black};
`;

const Hint = styled.p<{ type: Type }>`
  color: ${({ theme }) => theme.colors.grey400};
  ${({ type, theme }) =>
    type === 'openTask' &&
    css`
      color: ${theme.colors.green500};
      font-weight: 600;
    `}
  ${({ type }) =>
    type === 'resolvedTask' &&
    css`
      font-weight: 600;
    `}
  font-size: 12px;
`;

type HeaderProps = {
  title: string;
  type: Type;
};

const hints = {
  chat: 'chat.isAdminChat',
  openTask: 'chat.openTask',
  resolvedTask: 'chat.resolvedTask',
} as const;

const Header = ({ title, type }: HeaderProps) => (
  <Wrapper align="center" justify="space-between" gap={16}>
    <Title>{title}</Title>
    <Hint type={type}>{t(hints[type])()}</Hint>
  </Wrapper>
);

export default Header;
