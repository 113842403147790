import { Flex } from 'antd';

import { IconBilling } from '@assets';
import { Card } from '@components';

import * as S from './styled';

const BillingHistoryEmpty = () => (
  <Card title={t('settings.billing.billingHistory.title')()}>
    <Flex gap={16} align="center" vertical>
      <S.Icon>
        <IconBilling width={32} height={32} />
      </S.Icon>

      <Flex align="center" vertical>
        <S.Title strong>
          {t('settings.billing.billingHistory.noHistory.title')()}
        </S.Title>

        <S.Subtitle>
          {t('settings.billing.billingHistory.noHistory.description')()}
        </S.Subtitle>
      </Flex>
    </Flex>
  </Card>
);

export default BillingHistoryEmpty;
