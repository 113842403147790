import styled from '@emotion/styled';
import { Form } from 'antd';

export const FormItem = styled(Form.Item)`
  .ui-form-item-label {
    --ui-form-vertical-label-padding: 0 0 4px;

    label {
      color: ${({ theme }) => theme.colors.blue400};
      font-size: 12px;
      font-weight: 400;
    }
  }
`;
