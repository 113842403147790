//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$PaymentMethodController_intialize,
  ResponseContentType$PaymentMethodController_intialize,
  Response$PaymentMethodController_intialize$Status$201,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const usePaymentMethodController_intialize = () =>
  useApiClientMutation<
    Params$PaymentMethodController_intialize,
    Response$PaymentMethodController_intialize$Status$201[ResponseContentType$PaymentMethodController_intialize]
  >({ method: 'PaymentMethodController_intialize' });
