import { Button, Flex, Tabs, TabsProps } from 'antd';
import { useState } from 'react';

import { IconTrash } from '@assets';
import StatusTag from '@entities/common/StatusTag';

import { Project, ProjectStatus, toFrontendProjectStatus } from '..';
import ProjectCardDetails from './ProjectCardDetails';
import ProjectCardTransactions from './ProjectCardTransactions';
import * as S from './styled';

type ProjectCardProps = {
  project: Project;
  onProjectArchive: () => void;
  onProjectEdit: () => void;
};

const ProjectCard = (props: ProjectCardProps) => {
  const projectTabs = [
    {
      key: 'details',
      label: t('projects.projectTabs.details')(),
      children: (
        <ProjectCardDetails
          project={props.project}
          onEdit={props.onProjectEdit}
        />
      ),
    },
    {
      key: 'transactions',
      label: t('projects.projectTabs.transactions')(),
      children: <ProjectCardTransactions project={props.project} />,
    },
  ] as const satisfies TabsProps['items'];

  type ProjectTabKeys = (typeof projectTabs)[number]['key'];

  const [selectedProjectTab, setSelectedProjectTab] =
    useState<ProjectTabKeys>('details');

  return (
    <S.Card gap={20} vertical>
      <S.CardHeader justify="space-between" align="center">
        <Flex gap={16} align="center">
          <S.Title level={4}>{props.project.name}</S.Title>

          <StatusTag status={toFrontendProjectStatus[props.project.status]} />
        </Flex>

        {toFrontendProjectStatus[props.project.status] ===
          ProjectStatus.Active && (
          <Flex gap={16} align="center">
            <Button
              icon={<IconTrash />}
              size="small"
              onClick={props.onProjectArchive}
            >
              {t('projects.buttons.archive')()}
            </Button>
          </Flex>
        )}
      </S.CardHeader>

      <Tabs
        items={projectTabs}
        size="large"
        activeKey={selectedProjectTab}
        onChange={(key) => setSelectedProjectTab(key as ProjectTabKeys)}
      />
    </S.Card>
  );
};

export default ProjectCard;
