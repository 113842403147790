import 'dayjs/locale/en';
import 'dayjs/locale/fr';

import { ThemeProvider } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App as AppDesign, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HelmetProvider } from 'react-helmet-async';
import { IoProvider } from 'socket.io-react-hook';

import { GlobalStyles } from '@ui-kit/theme/GlobalStyles';
import { colors, config } from '@ui-kit/theme/theme';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { updateWeekStartForAllLocales } from '@utils';
import {
  AccountProvider,
  AppStateProvider,
  ConfirmationContextProvider,
} from '@context';
import { ModalManagerProvider } from '@context/ModalManager/ModalManager';

import AppRouter from './AppRouter';

const queryClient = new QueryClient();

dayjs.extend(updateLocale);

updateWeekStartForAllLocales();

const App = () => (
  <ErrorBoundary
    fallback={(errorData) => <ErrorFallback {...errorData} fullPage />}
  >
    <QueryClientProvider client={queryClient}>
      <IoProvider>
        <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={config}>
          <ThemeProvider theme={{ colors }}>
            <HelmetProvider>
              <AppDesign>
                <GlobalStyles />
                <AppStateProvider>
                  <AccountProvider>
                    <DndProvider backend={HTML5Backend}>
                      <ConfirmationContextProvider>
                        <ModalManagerProvider>
                          <AppRouter />
                        </ModalManagerProvider>
                      </ConfirmationContextProvider>
                    </DndProvider>
                  </AccountProvider>
                </AppStateProvider>
              </AppDesign>
            </HelmetProvider>
          </ThemeProvider>
        </ConfigProvider>
      </IoProvider>
    </QueryClientProvider>
  </ErrorBoundary>
);

export default App;
