import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography,
} from 'antd';
import { FC, useState } from 'react';

import { useAccount } from '@hooks';
import { Schemas } from '@api-client/generated/types';
import { Language } from '@constants';
import { useResendConfirmationEmail, useUpdateUserData } from '@hooks-api';

const { Title, Text } = Typography;

type PersonalInfoProps = {
  account: Schemas.User;
};

const PersonalInfo: FC<PersonalInfoProps> = ({ account }) => {
  const { setAccount } = useAccount();

  const { mutate: updateUserData, isPending: loading } = useUpdateUserData();
  const { mutate: resendConfirmationEmail } = useResendConfirmationEmail();
  const [emailError, setEmailError] = useState<string | null>();
  const [requestedConfirmationEmail, setRequestedConfirmationEmail] =
    useState(false);

  const onFinish = (value: Schemas.User) => {
    setEmailError(null);
    updateUserData(
      { requestBody: value },
      {
        onSuccess: (response) => {
          setAccount(response);
          message.success(t('settings.personal.success')());
        },
        onError: (error) => {
          for (const message of error.response?.data?.message || []) {
            if (message.startsWith('email.')) {
              setEmailError(message);
            }
          }
        },
      },
    );
  };

  const handleResendConfirmationEmail = () =>
    resendConfirmationEmail(
      {},
      {
        onSuccess: () => {
          setRequestedConfirmationEmail(true);
        },
      },
    );

  return (
    <Card bordered={false}>
      <Title level={5}>{t('settings.personal.infoTitle')()}</Title>

      <Form
        layout="vertical"
        colon={false}
        onFinish={onFinish}
        initialValues={account}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={t('settings.personal.name.label')()}
              rules={[
                {
                  required: true,
                  message: t('settings.personal.name.error')(),
                },
              ]}
            >
              <Input
                placeholder={t('settings.personal.name.placeholder')()}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="lastName"
              label={t('settings.personal.surname.label')()}
              rules={[
                {
                  required: true,
                  message: t('settings.personal.surname.error')(),
                },
              ]}
            >
              <Input
                placeholder={t('settings.personal.surname.placeholder')()}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              label={t('settings.personal.email.label')()}
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t('settings.personal.email.error')(),
                },
              ]}
              validateStatus={emailError ? 'error' : ''}
              help={
                emailError && // @ts-expect-error-next-line
                t(`settings.personal.${emailError}`)()
              }
              extra={
                !account.confirmedEmailAt && (
                  <Flex gap={4}>
                    <Text type="warning">
                      {t('settings.personal.infoEmailConfirm')()}
                    </Text>

                    {!requestedConfirmationEmail && (
                      <a onClick={() => handleResendConfirmationEmail()}>
                        <Text type="warning" strong>
                          {t('settings.personal.resendConfimationEmail')()}
                        </Text>
                      </a>
                    )}

                    {requestedConfirmationEmail && (
                      <Text type="warning" strong>
                        {t('settings.personal.sentConfirmationEmail')()}
                      </Text>
                    )}
                  </Flex>
                )
              }
            >
              <Input
                placeholder={t('settings.personal.email.placeholder')()}
                size="large"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="locale"
              label={t('settings.personal.locale.label')()}
            >
              <Select
                options={Object.keys(Language).map((key) => ({
                  // @ts-expect-error-next-line
                  label: t(`settings.personal.locale.labels.${key}`)(),
                  value: Language[key as keyof typeof Language],
                }))}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item noStyle>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            size="small"
          >
            {t('settings.personal.buttonSave')()}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default PersonalInfo;
