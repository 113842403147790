//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//

import {
  Params$TransactionIngestController_delete,
  ResponseContentType$TransactionIngestController_delete,
  Response$TransactionIngestController_delete$Status$200,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';
export const useTransactionIngestController_delete = () =>
  useApiClientMutation<
    Params$TransactionIngestController_delete,
    Response$TransactionIngestController_delete$Status$200[ResponseContentType$TransactionIngestController_delete]
  >({ method: 'TransactionIngestController_delete' });
