import type { ThemeConfig } from 'antd';
import { rgba } from 'emotion-rgba';

import { colorPalette } from '..';

const drawerTokens: ThemeConfig['components'] = {
  Drawer: {
    paddingLG: 32,
    colorBgMask: rgba(colorPalette.blue500, 0.6),
  },
};

export default drawerTokens;
