import { Button, type PopoverProps } from 'antd';
import _ from 'lodash';
import { FC, useCallback, useEffect, useId, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { IconChevronDown, IconChevronUp } from '@ui-kit/Icon';
import { ContactAction, ContactsSimpleList, SearchControl } from '@entities';
import { Loader, NotFoundComponent, Scrollbar } from '@components';
import { usePaginatedContactsList } from '@hooks';
import useClickOutside from '@hooks/useClickOutside';
import { Schemas } from '@api-client/generated/types';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { useModalManager } from '@context/ModalManager/useModalManager';

import * as S from './styled';

type Contact = Schemas.Contact;

type FilterAndAddContacts = {
  width?: number;
  selectedContact?: Contact | null;
  placeholder: string;
  isDisabled?: boolean;
  onChange: (id: string) => void;
} & PopoverProps;

const FilterAndAddContacts: FC<FilterAndAddContacts> = ({
  width,
  children,
  selectedContact,
  placeholder,
  isDisabled,
  onChange,
  ...rest
}) => {
  const contactCreate = useId();
  const [open, setOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [searchQuery, setSearchQuery] = useState(selectedContact?.name || '');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [valueIsSelected, setValueIsSelected] = useState(false);

  const modalManager = useModalManager();
  const isContactCreateVisible = modalManager.isOpen(contactCreate);
  const [debouncedSearch] = useDebounce(
    debouncedSearchTerm,
    DEFAULT_TIMEOUT_FOR_SEARCH,
  );

  const { rootRef, plainData, isFetching, hasNextPage, sentryRef } =
    usePaginatedContactsList({
      term: debouncedSearch,
      enabled: open || rest.open,
    });

  useEffect(() => {
    if (selectedContact) {
      setSearchQuery(selectedContact?.name);
    }
  }, [selectedContact]);

  const handleSelectContact = useCallback(
    (contact: Contact) => {
      setValueIsSelected(true);
      onChange(contact.id);
      setSearchQuery(contact.name);
      setOpen(false);
      setShowContent(false);
    },
    [onChange],
  );

  const handleAddNewContact = () => {
    modalManager.replace(contactCreate);
    setOpen(false);
    setSearchQuery('');
    setDebouncedSearchTerm('');
    setShowContent(false);
  };

  const handleSearchFocus = () => {
    setShowContent(!showContent);
  };

  const handleClose = () => {
    setShowContent(false);
  };

  const ref = useClickOutside(handleClose);

  const hasDefaultContact = !!selectedContact?.id || valueIsSelected;

  const suffixElement = useMemo(() => {
    if (hasDefaultContact) {
      if (!showContent) {
        return <IconChevronDown width={20} height={20} />;
      }

      return <IconChevronUp width={20} height={20} />;
    }

    return null;
  }, [hasDefaultContact, showContent]);

  const ComponentRender = useMemo(() => {
    if (isFetching) {
      return <Loader />;
    }

    if (plainData.length) {
      return (
        <Scrollbar
          className="contact-scrolbar"
          ref={rootRef}
          height={plainData.length <= 2 ? 'auto' : 276}
          withoutPadding
        >
          <ContactsSimpleList
            dataSource={plainData}
            loading={isFetching}
            onSelect={handleSelectContact}
          />

          {hasNextPage && <div ref={sentryRef} />}
        </Scrollbar>
      );
    }

    return (
      <NotFoundComponent
        title={t('contactsPage.notFound.title')()}
        text={t('contactsPage.notFound.text')()}
      />
    );
  }, [
    handleSelectContact,
    hasNextPage,
    isFetching,
    plainData,
    rootRef,
    sentryRef,
  ]);

  return (
    <>
      <ContactAction
        open={isContactCreateVisible}
        onCancel={() => modalManager.pop(contactCreate)}
        onAfterAction={handleSelectContact}
      />

      <S.Content ref={ref} width={440} vertical>
        <S.Inner isSelected={showContent} isDisabled={isDisabled}>
          <SearchControl
            placeholder={placeholder}
            showPrefix={!hasDefaultContact}
            suffix={suffixElement}
            allowClear={false}
            value={searchQuery}
            onFocus={handleSearchFocus}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setDebouncedSearchTerm(e.target.value);
            }}
            disabled={isDisabled}
            fitWidth
          />
        </S.Inner>

        {showContent && (
          <S.ScrollbarWrapper>
            {ComponentRender}
            <S.ButtonWrapper>
              <Button onClick={handleAddNewContact} size="small" block>
                {t('contactsPage.popupContacts.buttonAdd')()}
              </Button>
            </S.ButtonWrapper>
          </S.ScrollbarWrapper>
        )}
      </S.Content>

      {children}
    </>
  );
};

export default FilterAndAddContacts;
