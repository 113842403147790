import { ThemeProvider as EThemeProvider } from '@emotion/react';
import { App, ConfigProvider } from 'antd';
import type { PropsWithChildren } from 'react';

import colorPalette from './colorPalette';
import { GlobalStyles } from './GlobalStyles';
import { theme } from './tokens';

const ThemeProvider = ({ children }: PropsWithChildren) => (
  <ConfigProvider prefixCls="ui" iconPrefixCls="ui-icon" theme={theme}>
    <EThemeProvider theme={{ colorPalette }}>
      <App>
        <GlobalStyles />
        {children}
      </App>
    </EThemeProvider>
  </ConfigProvider>
);

export default ThemeProvider;
