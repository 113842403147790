import { Empty, Flex, Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import { useMemo } from 'react';

import Amount from '@entities/common/Amount';
import StatusTag from '@entities/common/StatusTag';
import { Loader, Scrollbar } from '@components';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { e2e } from '@e2e';

import {
  getTransactionsCountString,
  Project,
  ProjectListTabs,
  ProjectStatus,
  toFrontendProjectStatus,
} from '..';
import * as S from './styled';

type ProjectsListProps = {
  projects: Project[];
  activeKey: ProjectListTabs;
  selectedProjectId: string | null;
  loading: boolean;
  hasNextPage: boolean;
  onTabClick: (key: ProjectListTabs) => void;
  onProjectClick: (project: Project) => void;
  onLoadMore: () => void;
};

const ProjectsList = (props: ProjectsListProps) => {
  const tabs = useMemo(
    () =>
      [
        { key: 'all', label: t('projects.filterTabs.all')() },
        { key: ProjectStatus.Active, label: t('projects.filterTabs.active')() },
        {
          key: ProjectStatus.Archived,
          label: t('projects.filterTabs.archived')(),
        },
      ] as const satisfies TabsProps['items'],
    [],
  );

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: props.loading,
    onLoadMore: props.onLoadMore,
    hasNextPage: props.hasNextPage,
  });

  return (
    <Flex gap={16} vertical>
      <Tabs
        items={tabs}
        size="large"
        activeKey={props.activeKey}
        onChange={(key) => props.onTabClick(key as ProjectListTabs)}
      />

      <Scrollbar height="calc(100vh - 380px)" ref={rootRef}>
        <Flex data-testid={e2e.locators.projectsPage.list} gap={16} vertical>
          {props.loading ? (
            <Loader />
          ) : props.projects.length === 0 ? (
            <Empty />
          ) : (
            props.projects.map((project) => (
              <S.ListItem
                key={project.id}
                data-testid={`${e2e.locators.projectsPage.listItem}-${project.name}`}
                isActive={project.id === props.selectedProjectId}
                onClick={() => props.onProjectClick(project)}
                role="button"
                tabIndex={0}
              >
                <Flex gap={16} justify="space-between">
                  <S.ListItemHeader
                    data-testid={`${e2e.locators.projectsPage.listItem}-${project.name}-name`}
                  >
                    <S.ListItemName>{project.name}</S.ListItemName>

                    <S.Count>{getTransactionsCountString(project)}</S.Count>
                  </S.ListItemHeader>

                  <StatusTag
                    status={toFrontendProjectStatus[project.status]}
                    data-testid={`project-list-item-${project.name}-status`}
                  />
                </Flex>

                <Amount
                  amount={project.totalAmount}
                  currencyCode={DEFAULT_CURRENCY_CODE}
                />
              </S.ListItem>
            ))
          )}
        </Flex>

        {props.hasNextPage && <div ref={sentryRef} />}
      </Scrollbar>
    </Flex>
  );
};

export default ProjectsList;
