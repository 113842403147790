import { Tabs as BaseTabs } from 'antd';

import styled from '@emotion/styled';

export const Sidebar = styled.div`
  min-width: 340px;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const Content = styled.div`
  flex: 1;
`;

export const Wrapper = styled.div`
  padding: 30px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkgrey};
  background: ${({ theme }) => theme.colors.grey100};
  min-height: 100%;
  max-height: 0;
  display: flex;
  align-items: stretch;
  gap: 50px;
  flex: 1;
`;

export const Tabs = styled(BaseTabs)`
  height: 100%;

  .ui-tabs-content {
    height: 100%;
  }

  .ui-tabs-tabpane {
    height: 100%;
  }
`;

export const EmptyChat = styled.div`
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.darkgrey};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${({ theme }) => theme.colors.grey400};
  text-align: center;
  font-size: 12px;
`;
