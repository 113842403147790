import { Table } from 'antd';

import { Card, Loader } from '@components';

import BillingHistoryEmpty from '../BillingHistoryEmpty';
import { getTableColumns } from './helpers';
import { useBillingDocumentList } from './hooks';

const BillingHistory = () => {
  const tableColumns = getTableColumns();

  const { data, isPending } = useBillingDocumentList();

  if (isPending) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  if (!data?.length) {
    return <BillingHistoryEmpty />;
  }

  return (
    <Card title={t('settings.billing.billingHistory.title')()}>
      <Table
        columns={tableColumns}
        dataSource={data}
        loading={isPending}
        pagination={false}
      />
    </Card>
  );
};

export default BillingHistory;
