import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';

import { Api, ApiTypes } from '@api';

export const schema = z.object({
  status: z.optional(z.enum(['active', 'inactive', 'finished'])),
  term: z.optional(z.string().default('')),
  page: z.optional(z.number().default(1)),
  perPage: z.optional(z.number().default(10)),
});

export type Schema = z.infer<typeof schema>;

const queryKeys = {
  all: 'projects',
  list: (filters?: Schema) => ['projects', 'list', filters || {}],
  detail: (id: string) => ['projects', 'detail', id],
} as const;

function getCachedDefaultProjectList(
  client: ReturnType<typeof useQueryClient>,
): ApiTypes.ProjectControllerFindAllV2Response | undefined {
  return client.getQueryData<ApiTypes.ProjectControllerFindAllV2Response>(
    queryKeys.list(),
  );
}

export function useProjectList(params: Schema) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKeys.list(params),
    queryFn: () => Api.projectControllerFindAllV2(params),
    staleTime: 1000 * 20,
    placeholderData: () => {
      if (params.status) {
        const projects = getCachedDefaultProjectList(queryClient);
        if (projects?.records.length) {
          return {
            ...projects,
            records: projects.records.filter(
              (project: ApiTypes.ProjectResponseDto) =>
                project.status === params.status,
            ),
          };
        }
      }
    },
  });
}

export function useProjectEdit() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (project: ApiTypes.Project) =>
      Api.projectControllerUpdateOneById(project.id, project),
    onMutate: (newProject) => {
      const projects = getCachedDefaultProjectList(queryClient);
      queryClient.setQueryData(queryKeys.list(), {
        ...projects,
        records: projects?.records.map((project) => project.id === newProject.id ? newProject : project),
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: queryKeys.list() });
    },
  });
}

export function useProjectCreate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: Api.projectControllerCreate,
    onMutate: (newProject) => {
      const projects = getCachedDefaultProjectList(queryClient);
      queryClient.setQueryData(queryKeys.list(), {
        ...projects,
        records: [newProject, ...projects?.records || []], 
      })
    },
  });
}

export function useProjectById(id: string) {
  const queryClient = useQueryClient();

  return useQuery<ApiTypes.ProjectResponseDto>({
    queryKey: queryKeys.detail(id),
    queryFn: () => Api.projectControllerFindOneByIdV2(id),
    staleTime: 1000 * 20,
    initialData: () => queryClient.getQueryData(queryKeys.detail(id)),
  });
}
