import { Col, Flex, Row } from 'antd';
import { FC } from 'react';

import { PersonalInfo, PersonalSecurity } from '@entities';
import { PageWrapper } from '@components';
import { useAccount } from '@hooks';

const SettingsPersonal: FC = () => {
  const { account } = useAccount();

  return (
    <PageWrapper
      title={t('settings.title')()}
      meta={{ title: t('settings.title')() }}
      isLoading={!account}
    >
      <Row gutter={[32, 0]}>
        <Col span={15}>
          <Flex gap={32} vertical>
            {account && <PersonalInfo account={account} />}

            <PersonalSecurity />
          </Flex>
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default SettingsPersonal;
