import { useEffect } from 'react';
import { useSocket } from 'socket.io-react-hook';

import { useAccount } from './index';

const SOCKET_URL = import.meta.env.VITE_ROUTE_API_BASE_URL;

type SocketClientProps = {
  namespace: string;
  customUrl?: string;
};

const useSocketClient = ({ namespace, customUrl }: SocketClientProps) => {
  const { accessToken, companyId } = useAccount();

  const { socket, error } = useSocket(
    customUrl || `${SOCKET_URL}/${namespace}_${companyId}`,
    {
      auth: {
        accessToken,
      },
      transports: ['websocket'],
    }
  );

  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        console.log('🟢 Connected to socket server');
      });

      socket.on('disconnect', () => {
        console.log('🔴 Disconnected from socket server');
      });

      return () => {
        socket.off('connect');
        socket.off('disconnect');
      };
    }
  }, [socket]);

  return { socket, error };
};

export default useSocketClient;
